// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { oldTranslationFile } from "./oldTranslationFile";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        ...oldTranslationFile.en,
        loading: {
          txt: "We are preparing everything...",
        },
        stats: {
          menu: {
            visitors: "Visitors",
            conversions: "Conversions & bookings",
            reservations: "Stays & cancellations",
            cancellations: "Reasons for cancellation",
            evolutionGainsMonth: "Evolution of earnings",
            evolutionGains: "Comparison of income by period",
          },
          noResidence: "No residence found",
          search: "Search",
          allResidences: "All residences",
          export: "Export results",
          year: "Year",
          visitors: {
            title: "Number of visitors",
            titleExport: "Visitor statistics",
            miniTitle: "Visitor",
            emp: "Total",
            percentage: "Percentage",
          },
          conversions: {
            title: "Conversion rate",
            titleExportConversion: "Conversion rate statistics",
            titleExportReservations: "Booking statistics",
            title2: "Total number of bookings",
            visitors: "Visitors",
            reservations: "Bookings",
            miniVisitors: "Visitor",
            miniReservations: "Booking",
            total: "of the total",
          },
          reservations: {
            title: "Cancellation rate",
            title2: "Average stay length",
            titleExport: "Stay and cancellation statistics",
            nights: "night",
            nights2: "nights",
            total: "of the total",
            miniReservations: "Booking",
            miniAnnulations: "Cancellation",
            reservations: "Bookings",
            annulations: "Cancellations",
          },
          reasonCancellation: {
            title: "Reasons",
            titleExport: "Cancellation reasons",
            annulation: "cancellation",
            other: "Others",
            header1: "Label",
            header2: "percentage",
          },
          cancellations: {
            title: "Cancellation reasons",
            titleExport: "Cancellation statistics",
            annulation: "cancellation",
            other: "Others",
            header1: "Label",
            header2: "pourcentage",
          },
          evolutionGainsMonth: {
            title: "Earnings evolution",
            titleExport: "Monthly earnings evolution",
            header1: "Month",
            header2: "Gains",
          },
          evolutionGains: {
            croissance: "Growth rate",
            titleExport: "Earnings evolution by period",
            header1: "Period",
            header2: "Gains",
          },
          reservationAverage: {
            title: "Average revenue",
            titleExport: "Average revenue",
            reservation: "Booking",
            cancellation: "Cancellation",
            average: "Average",
            header1: "Label",
            header2: "Value in %",
          },
        },
        modals: {
          choiceLanguageDevise: {
            selectLangueLabel: "Choose language",
            selectDeviseLabel: "Choose currency",
            confirmTxt: "Confirm",
            francaisName: "French",
            anglaisName: "English",
          },
          addLessDiv: {
            required: "* Required",
          },
          connexionPanel: {
            inscription: {
              title: "Sign up!",
              confirmTxt: "Continue",
              orTxt: "or",
              otherChoice: "Already have an account",
              connexionTxt: "login",
              social: {
                google: "Continue with Google",
                facebook: "Continue with Facebook",
              },
              champs: {
                mail: "Email address",
                telephone: "Phone number",
                issueMail: "Please enter a valid email address",
                issueTel:
                  "Please enter a valid phone number with country code +xxx",
                issueTelDifferent:
                  "Enter a valid phone number different from the previous one",
                password: "Password",
                issuePassword:
                  "Minimum 8 characters, at least one uppercase and one number",
              },
            },
            finish: {
              title: "Sign up!",
              champs: {
                lastname: "Last name",
                firstname: "First name",
                password: "Password",
              },
              process: {
                txt: "By continuing this process, you accept our",
                condition: "terms of use",
                and: "and",
                policy: "privacy policy",
              },
              issuePassword:
                "Minimum 8 characters, at least one uppercase and one number",
              issueNameType: "Please enter a valid name",
              btn: "Sign up",
            },
            connexion: {
              title: "Log in",
              champs: {
                mail: "Email address",
                password: "Password",
              },
              social: {
                google: "Continue with Google",
                facebook: "Continue with Facebook",
              },
              or: "or",
              forgetPassword: "Forgot password?",
              btn: "Login",
              notHaveAccount: "Don't have an account?",
              inscription: "Sign up",
            },
            forgetPassword: {
              title: "Forgot password",
              codeReceiveTxt: "Enter the code you received at",
              validityCodeTxt: " it is valid for 5 minutes.",
              champs: {
                placeholderCode: "Code",
                placeholderMail: "Email address",
              },
              issueEmailType: "Please enter a valid email address",
              reSendTxt: "Resend",
              enterCode: "Enter a valid code",
              checkCode: "Verify code",
              newAdresseTxtInfo:
                "Enter the email address associated with your account. We will send you a link by email to reset your password.",
              btn: "Send link",
            },
            newPassword: {
              title: "Change password",
              description:
                "The new password must contain at least one symbol or number, be at least 8 characters long and be different from the old one.",
              champs: {
                password: "Password",
                confirmPassword: "Confirm password",
              },
              issuePassword: "Invalid password",
              issueConfirmPassword: "Passwords are different",
              btn: "Change password",
            },
            notification: {
              connexionDoneTxt: "Successfully logged in",
              errorConnexionTxt: "Incorrect password or email",
              inscriptionDoneTxt: "Successfully registered",
              blockAccount:
                "Your account has been blocked by Trouvechap, please contact us by email.",
              blockClient:
                "Your account has been blocked by Trouvechap, please contact us by email.",
              paymentMethod:
                "Don't forget to set up your payment method in your profile.",
              socials: {
                googleLogin: "Successfully logged in with Google",
                googleLoginError: "Failed to login with Google",
                facebookLogin: "Successfully logged in with Facebook",
                facebookLoginError: "Failed to login with Facebook",
              },
              emailAlreadyExist:
                "A user with this email address already exists",
              codeSendTxt: "A password reset code has been sent to your email",
              userNoExistTxt: "This user does not exist",
              codeNoValidTxt: "The code is not valid",
              errorRegistrationTxt: "An error occurred during registration",
              tryAgainTxt: "Please try again",
              passChanged: "Password successfully changed",
            },
          },
          whatsapp: {
            title: "Contact support via Whatsapp",
            description: "You can contact us via Whatsapp for any assistance",
            btn: "Contact",
          },
          verifyEmail: {
            title: "Verify your email address",
            text1:
              "Your email address has been successfully verified, see you later on trouvechap",
            errorTxt:
              "The token used is invalid, please request another one from the profile menu",
          },
          piece: {
            title: "My ID document",
            titleCheck: "Confirm sending your document",
            txt: {
              p1: "Your ID document is used to verify your identity and fully use our platform's services.",
              file: "PDF FILE",
              p2: "Click here to view the document",
              p3: "Update document",
              p4: "Updating your ID document will put your already validated residences on hold for verification.",
              p5: "Click here to verify the document",
              confirmTxt: "Do you confirm sending your document?",
            },
            action: {
              confirm: "Confirm",
              cancel: "Cancel",
            },
          },
          photo: {
            title: "Confirm profile photo change",
            confirmPhotoChange: "Do you confirm changing your profile photo?",
            confirmTxt: "Confirm",
          },
          nameUpdate: {
            title: "Update your first and last name",
            infoTxt:
              "Updating your name will put your already validated residences on hold for verification.",
            confirmTxt: "Confirm",
            champs: {
              lastname: "Last name",
              firstname: "First name",
            },
            error: {
              issueNameType: "Please enter a valid name",
            },
            notification: {
              errorUpdateName: "An error occurred during the update",
            },
          },
          personalInfo: {
            password: {
              title: "Change password?",
              description:
                "The new password must contain at least one symbol or number, be at least 8 characters long and be different from the old one",
              error: {
                issuePassword: "Invalid password",
                samePassword: "Invalid or identical password",
              },
              champs: {
                password: "Current password",
                newPassword: "New password",
              },
              button: "Change password",
            },
            telephone: {
              title: "Change phone number?",
              description: "Enter the phone number with country code",
              button: "Change phone number",
            },
            habitation: {
              title: "Change place of residence?",
              description: "Enter your correct place of residence.",
              champs: {
                habitation: "New place of residence",
                error: "Please enter a valid place of residence",
              },
              button: "Change place of residence",
            },
            mail: {
              title: "Change email address",
              champs: {
                mail: "New email address",
                error: "Please enter a valid email address",
              },
              button: "Change email address",
            },
            paiementMethod: {
              title: "Your payment methods",
              loading: "Updating your payment methods, please wait...",
              noPaiementMethod:
                "You don't have any payment methods, please add one by clicking the button below.",
              comment: "How would you like to receive your money?",
              oneMethod: "You have one payment method.",
              canModify:
                "You can modify the default payment method for your reservations.",
              default: "Default",
              defaultMethod: "Default payment method",
              addNewMethod: {
                p1: "Add a",
                p2: "new",
                p3: "payment method",
              },
              modals: {
                addNewMethod: {
                  addTitle: "Add a payment method",
                  editTitle: "Edit payment method",
                },
                confirmMethod: {
                  title: "Payment method confirmation",
                  description:
                    "After confirmation, you won't be able to modify the payment method for this reservation.",
                  infos: {
                    p1: "earnings paid between",
                    days1: "7 and 14 days",
                    days2: "2 and 4 days",
                    p2: "after the start of the client's stay",
                  },
                },
                txts: {
                  bank: "Bank account",
                  temporary: "Temporary payment",
                  temporaryDescription:
                    "This payment will be automatically deleted after closing the web page",
                  bankName: "Bank name",
                  rib: "Bank details",
                  versementMethod: "Payment method",
                },
                champs: {
                  fullname: "Full name",
                  telephone: "Phone number",
                  tel: "Phone",
                },
                button: {
                  goDefault: "Set as default",
                  delete: "Delete",
                  save: "Save",
                  add: "Add",
                  confirm: "Confirm",
                },
                notification: {
                  editDone: "Payment method modified",
                  editTemporaryDone: "Temporary payment method modified",
                  errorDefaultMethodTemporary:
                    "You cannot set a temporary payment method as default",
                  errorAlreadyDefault: "This payment method is already default",
                  temporaryMethodAddDone: "Temporary payment method added",
                  methodAddDone: "Payment method added",
                  methodEditDefaultDone: "Default payment method modified",
                  deleteDone: "Payment method deleted",
                  deleteDefaultMethod:
                    "You cannot delete the default payment method",
                  oneMethodError: "You cannot delete your only payment method",
                  blockTemporaryAddError:
                    "You must have at least one payment method to add a temporary payment",
                  cannotModifyTemporaryStatus:
                    "You cannot modify the status of a temporary payment",
                },
              },
            },
            notification: {
              noPaiementMethod: "No payment method chosen",
              motPasseSwitchDone: "Password successfully changed",
              passwordError: "Current password incorrect",
              motPasseSwitchError:
                "An error occurred while changing the password",
              confirmPassTel: "Confirm code sent by SMS",
              phoneAlreadyUsed: "This phone number is already in use",
              telError: "An error occurred while changing the phone number",
              changeLocationDone: "Place of residence successfully changed",
              changeLocationError:
                "An error occurred while changing the place of residence",
              mailChangeError1: "You cannot change your email",
              mailChangeError2: "This account is linked to a social network",
              confirmCodeMail: "Confirm code received by email",
              mailAlreadyUsed: "This email address is already in use",
              mailChangeError:
                "An error occurred while changing the email address",
            },
          },
          tokenTelCheck: {
            title: "OTP Code",
            description:
              "Send a verification code to your phone number to verify it",
            secondDescription:
              "Enter the OTP code received by SMS to verify your phone number",
            button: {
              sendCode: "Send code",
            },
            notification: {
              success: "Phone number successfully changed",
              newNumber: "The new phone number is",
              errorCode: "Incorrect OTP code",
              confirmCode: "Confirm code received by SMS",
              errorSendCode: "Failed to send OTP code, please try again later",
            },
          },
          tokenMailCheck: {
            title: "OTP Code",
            description:
              "Enter the OTP code received by email to verify your email address",
            notification: {
              success: "Email address successfully changed",
              errorCode: "Incorrect OTP code",
              confirmCode: "Confirm code received by email",
              errorSendCode: "Failed to send OTP code, please try again later",
            },
          },
          booking: {
            titles: {
              versementMethod: "Selected payment method",
              confirmRefund: "Refund confirmed",
              cancelRefund: "Cancel request",
              details: "Booking details",
              remboursementAskTxt: "Refund request",
              cancelReservationTxt: "Cancel booking",
              confirmRemboursementTxt: "Confirm request",
              confirmCancelReservation: "Do you confirm canceling the booking?",
            },
            txts: {
              motifCanceledValue1: "Accommodation not available upon arrival",
              motifCanceledValue2: "Non-compliance of the accommodation",
              motifCanceledValue3: "Location problem",
              motifCanceledValue4: "Non-existent residence",
              motifCanceledValue5: "I had an impediment",
              motifCanceledValue6: "I had a disagreement with the host",
              motifCanceledValue0: "Other",
              motifCanceledValue0Placeholder: "Write your cancellation reason",
              titleCancelReason:
                "Please choose a cancellation reason among those proposed below",

              motifCanceled: "Cancellation reason",
              titlePersonneTxt: "Number of people",
              doAskRemboursementTxt:
                "You will be refunded after Trouvechap's validation.",
              noMotif: "No particular reason",
              ichooseOtherResiTxt: "I chose another residence",
              iChangedMyMindTxt: "I changed my mind",
              clientName: "Client",
              hoteTxt: "Host",
              serialNumber: "Booking ID",
              residenceLocation: "Residence location",
              seeOnGoogleMap: "View on Google Map",
              paymentMethod: "Chosen payment method",
              refundDetailsTitle: "Refund details",
              totalRefund: "Total Refund",
              refundDetails:
                "Your refund request is being reviewed, you will be notified once the refund is accepted by Trouvechap",
              refundDetailsAccepted1: "Your refund request was confirmed on",
              refundDetailsAccepted2:
                "by Trouvechap. However, you can contact customer service for more details.",
              myCode: "My code",
              myCodeDescription: "Present this code to your host upon arrival.",
              personneTxt: "Person",
              personneTxtPlural: "People",
              arrivedPlaceholderTxt: "Arrival",
              departPlaceholderTxt: "Departure",
              payment: "Payment",
              paymentDescription:
                "A payment link is open, it will last 5 minutes and is being processed, you will receive an email and be notified once the payment is validated or canceled.",
              gainsTotal: "Total earnings",
              refund: {
                title: "Information",
                p1: "Trouvechap confirmed the client's refund request on",
                p2: "by Trouvechap. you will receive a portion of the reservation amount according to your refund conditions. you will be notified and contacted by customer service. For more information you can contact Trouvechap service.",
                p3: "The client has requested a refund, it is being reviewed (it will respect your refund conditions)",
              },
              infoRemboursementWarningTxt:
                "You will be refunded according to the host's cancellation conditions at the time of booking, please make sure to check them before requesting a refund",
              annulation: "Cancellation",
              notRemboursement: "No refund",
              amountToGiveTxt: "Amount to refund:",
              totalMontantTxt: "of total amount",
              preview: {
                title: "Refund preview",
                p1: "Based on the host's cancellation conditions, you will be refunded",
                p2: "of the total amount so",
                p3: "from Trouvechap",
              },
              price: {
                subtotal: "Base subtotal",
                tarifaire: "Rate subtotal",
                baseGains: "Base earnings",
                gainsNoReduction: "Earnings without reduction",
                paymentNoReduction: "Payment without reduction",
                gainsAfterIncrease: "Earnings after increase",
                gainsAfterDecrease: "Earnings after reduction",
                totalGains: "Total earnings",
                totalPayment: "Total payment",
                paymentWithReduction: "Payment with reduction",
                gainsTotauxTxt: "Total earnings",
              },
              commission: {
                hote: "Trouvechap takes 10% commission on the rate subtotal of the booking",
                client:
                  "Service fees are applied to provide protection: cancellation, 24/7 assistance and accommodation compliance.",
              },
            },
            button: {
              closePanel: "Close",
              cancelRefund: "Cancel request",
              confirmTxt: "Confirm",
              cancelReservation: "Cancel booking",
              askRemboursement: "Request refund",
              callHote: "Contact us",
            },
          },
          deleteResidence: {
            title: "Are you sure you want to delete these residences?",
            description:
              "By deleting these residences, you will also delete all related data. No going back.",
            deleteTxtNumber: "The following residences will be deleted",
            deleteTxtOne: "The following residence will be deleted",
            btn: "Delete",
            notification: {
              deleteResidenceDone: "Deletion completed",
              errorDeleteResidence: "An error occurred during deletion",
            },
          },
          changeStatusResidence: {
            titles: {
              changeStatus: "Change status of the following residences",
              changeStatusOne: "Change status of the following residence",
            },
            description1:
              "Deactivated residences will not be visible to users.",
            description2:
              "(Trouvechap will need to validate the residences before they are visible again)",
            btn: "Change status",
          },
          share: {
            title: "Share",
            description: "Share on social networks",
            textMessage: "Hello, could I get some help?",
            action: {
              copyLink: "Copy link",
              mail: "Email address",
              whatsapp: "Whatsapp",
              sendDirect: "Messages",
            },
            txts: {
              title: "Residence on Trouvechap",
              subject: "Wow residence on Trouvechap!",
              body: "Hi,\n\nI found this super interesting residence on Trouvechap website: ",
              textMessage:
                "Hi,\n\nI found this super interesting residence on Trouvechap website: ",
              textMessageWhatsapp:
                "Hi,\n\nI found this super interesting residence on Trouvechap website: ",
              textDirect:
                "Hi,\n\nI found this super interesting residence on Trouvechap website: ",
            },
            notification: {
              shareDone: "Residence successfully shared",
              linkCopied: "Link successfully copied",
              errorCopyLink: "Error copying link",
              errorShare: "An error occurred while sharing",
              linkSend: "Link successfully sent",
              errorSendLink: "Error sending link",
              errorShareLink: "Error sharing link",
            },
          },
          galerie: {
            title: "Residence gallery",
            txts: {
              photos: "Photos",
            },
            action: {
              prev: "Previous",
              next: "Next",
              close: "Close",
              galeriePhotoTxt: "Photo gallery",
              share: "Share",
              save: "Save",
            },
          },
          signalement: {
            label: "Why do you want to report this listing?",
            description: "This action will not be shared with the host.",
            required: "* Required",
            notification: {
              alreadySignaled: "You have already reported this listing",
              youNeedConnect: "You must be logged in to report a listing",
              signalDone: "Report submitted",
              errorSignal: "An error occurred while reporting",
            },
            action: {
              next: "Next",
              send: "Send",
            },
            miniModal: {
              title: "Write your message",
              placeholder: "Write your message here",
            },
            champs: {
              reason1: "This listing is false",
              reason2: "This is not a real place",
              reason3: "This residence has already been posted",
              reason4: "This residence belongs to me",
              reason5: "Other",
            },
          },
        },
        header: {
          title: "Trouvechap",
          btns: {
            hote: "Host mode",
            help: "Help",
            listResi: "List my residence",
            listStore: "View all our residences",
            login: "Login / Sign up",
            message: "Messages",
            notification: "Notifications",
            fav: "Favorites",
            profile: "Profile",
            booking: "My bookings",
            listResidence: "List my residence",
            disconnect: "Logout",
          },
          notification: {
            needConnectForPage: "You must be logged in to access this page.",
            soonAvailable: "Feature coming soon",
          },
          store: {
            champs: {
              destination: {
                fromMap: "From map",
                placeholder: "Where do you want to go?",
              },
              date: {
                from: "Arrival",
                to: "Departure",
                datePlaceholder: "Date",
              },
              traveler: {
                title: "Number of people",
                placeholder: "Add travelers",
                personneTxt: "Person",
                personneTxtPlural: "People",
                menu: {
                  label: "Number of people",
                  eraseAll: "Clear all",
                  validate: "Validate",
                },
              },
            },
            notification: {
              dateError: "Select another date",
              otherDateError:
                "The configured booking covers a period when the residence is unavailable",
            },
          },
          hote: {
            mesResiMenuTxt: "My residences",
            calendarMenuTxt: "Calendar",
            reservationMenuTxt: "Bookings",
            statistiquesMenuTxt: "Statistics",
          },
        },
        footer: {
          links: {
            faq: "FAQ",
            privacyPolicy: "Privacy policy",
            termsAndConditions: "Terms and conditions",
            legalMentions: "Legal mentions",
          },
          share: {
            title: "Share",
            description: "Share on social networks",
            textMessage: "Hello, could I get some help?",
          },
          form: {
            title:
              "Receive the latest information about new services and products from Trouvechap.",
            inputPlaceholder: "Enter your email address",
            btn: "Send",
          },
          notification: {
            addNewsletterDone: "You are now subscribed to our newsletter",
            errorSendNewsletter:
              "This email address is already in our newsletter",
          },
          rightTxt: "© 2024 Trouvechap. All rights reserved.",
        },
        home: {
          hero: {
            title: "Romantic getaway or outing with friends?",
            titleBig: "Find the residence you need",
          },
          spotlightedResidences: {
            title: "Our most visited residences",
            seeMore: "See more",
            seeAll: "See more residences",
          },
          paiement: {
            title: "Multiple payment methods",
            descriptionP1: "Book and pay securely with",
            descriptionP2: "the service that suits you",
          },
          popularResidence: {
            title:
              "List your residence on Trouvechap and open the door to rental income.",
            descriptionP1: "More than",
            descriptionP2:
              "residences already listed with us, so what are you waiting for?",
            actionBtn: "List my residence",
            annonces: {
              top: "Earn money with your resi",
              bottom: "Noria Bassam Residence",
            },
          },
          howWork: {
            title: "How does trouvechap work?",
            description:
              "Find and book the residence you need with Trouvechap, it's super easy!",
            cards: {
              one: {
                title: "Find your residence",
                description:
                  "Browse our list of over 1000 furnished residences that are easily accessible and with very competitive prices.",
              },
              two: {
                title: "Compare offers",
                description:
                  "Refine your search criteria according to what you want to have in your residence and compare the different offers from our list.",
              },
              three: {
                title: "Book the one you choose",
                description:
                  "Satisfied? Set a date and book the residence you chose simply and quickly.",
              },
            },
          },
          mobileApp: {
            title: "It's always as",
            highlightedTxt: "Chap and easy",
            titleSub: "with the mobile app.",
            description:
              "With our mobile app, find the residence of your dreams and book it in moments, wherever you are. No more long waits and uncertainties.",
            downloadBtn: {
              soon: "Coming soon, stay connected!",
              google: "Download on Google Play",
              appStore: "Download on App Store",
            },
          },
          contactUs: {
            title: "We would love to hear from you",
            description: "Write to us for any questions or concerns.",
            champs: {
              mail: "Email address",
              object: "Email subject",
              message: "Message",
            },
            btn: "Send",
          },
        },
        store: {
          map: {
            search: "From map",
            title: "Find the residence of your dreams",
            storeMap: {
              action: {
                showMap: "Show map",
                closeMap: "Close map",
              },
              notification: {
                positionSuccess: "Position successfully retrieved",
                positionError: "Error retrieving your position",
                positionDenied:
                  "You have denied geolocation. Please enable it in your browser settings.",
                positionErrorBrowser:
                  "Your browser does not support geolocation. Please enable it in your browser settings.",
                youNeedConnect: "You must be logged in to access this page.",
              },
            },
          },
          big: {
            champs: {
              destination: {
                title: "Destination",
                placeholder: "Where do you want to go?",
              },
              from: {
                title: "Arrival",
                placeholder: "Date",
              },
              to: {
                title: "Departure",
                placeholder: "Date",
              },
              personne: {
                title: "Number of people",
                placeholder: "Add travelers",
                personneTxt: "Person",
                personneTxtPlural: "People",
                menu: {
                  label: "Number of people",
                  eraseAll: "Clear all",
                  validate: "Validate",
                },
              },
            },
          },
          little: {
            title: "Enter a destination",
            day: {
              nightTxt: "Night",
              nightTxtPlural: "Nights",
              placeholder: "Date",
            },
            traveler: {
              title: "Add travelers",
              txt: "Traveler",
              txtPlural: "Travelers",
            },
          },
          panel: {
            mobile: {
              champs: {
                destination: {
                  label: "Destination",
                  placeholder: "Search a destination",
                  lastSearch: "Recent searches",
                },
                calendar: {
                  title: "When do you want to travel?",
                  notification: {
                    selectOtherDate: "Please select another date",
                    indisponibleDate:
                      "The configured booking covers a period when the residence is unavailable",
                  },
                },
                traveler: {
                  title: "Number of people",
                },
              },
              action: {
                erase: "Clear all",
                search: "Search",
              },
            },
          },
          top: {
            btnFilters: "Filters",
          },
          notification: {
            destinationInputError: "Please enter a destination",
            addDateInputError: "Please enter arrival and departure dates",
            addPersonneInputError: "Please enter number of people",
          },
          filters: {
            title: "Filters",
            range: {
              title: "Price range",
              min: "Minimum",
              max: "Maximum",
              night: "Per Night",
            },
            room: {
              title: "Number of rooms",
              bedroom: {
                bedroomTxt: "Bedroom",
                bedroomTxtPlural: "Bedrooms",
              },
              bathroom: {
                bathroomTxt: "Bathroom",
                bathroomTxtPlural: "Bathrooms",
              },
              salon: {
                salonTxt: "Living room",
                salonTxtPlural: "Living rooms",
              },
            },
            occupation: {
              title: "Occupation",
              full: {
                title: "Entire residence",
                description: "Guests have the entire residence.",
              },
              shared: {
                title: "One room",
                description:
                  "Guests have their own room in the residence and access to shared spaces.",
              },
              commodities: {
                title: "Amenities",
                equipments: "Basic Equipment",
                security: "Security and Comfort",
                leisure: "Relaxation and Leisure",
                convenience: "Convenience and Services",
                other: "Others",
                social: "Social activities",
              },
            },
            social: {
              title: "Social activities",
              events: "Daytime Festive Events",
              nightEvents: "Evening Festive Events",
              education: "Learning and Education",
              arts: "Artistic and Creative Activities",
            },
            activities: {
              title: "Learning and Education",
            },
            arts: {
              title: "Artistic and Creative Activities",
            },
            residence: {
              title: "Residence type",
              home: "House",
              appart: "Apartment",
              chalet: "Chalet",
            },
            action: {
              resetAll: "Clear all",
              search: "Search",
            },
          },
          page: {
            endResult: "End of results!",
            endResultTxt:
              "Don't hesitate to try other filter combinations to find the best residence for you.",
            seeMore: "See more",
            tryMap: "or try via the map.",
          },
        },
        listPopuResidence: {
          btn: "List my residence",
          notification: {
            infoNeedConnect: "You must first have a Trouvechap account",
          },
          faq: {
            title: "FAQ",
            description:
              "Get quick answers to all your questions and concerns about Trouvechap. We have ready answers for you, whether you're a guest, host or just a member.",
            cards: {
              one: {
                title: "How to book accommodation on Trouvechap?",
                subtitle: "Booking accommodation is simple:",
                one: "Enter the destination, dates, and number of residents who will stay.",
                two: "Add filters according to your requirements (pool, jacuzzi, possibility to organize an event)",
                three: "Then select the accommodation you want",
                four: "Click on ",
                four2: "book",
                five: "Then enter your details, then click on",
                five2: "booking request.",
                six: "A request is then sent to the host. Payment can be made when the host has accepted your request.",
              },
              two: {
                title: "How to list accommodation on Trouvechap?",
                description: "Click on list my residence",
                one: "Click on",
                one2: "list my residence",
                two: "Follow the different steps",
                three:
                  "You will be notified when the Trouvechap team has reviewed your request.",
              },
              three: {
                title: "As a host, how to cancel a booking?",
                description: `As a host you can be led to cancel a booking for various reasons, regardless of your will. To do this, please contact the Trouvechap team by phone or Whatsapp. However, it is not recommended to cancel a booking. This could have an impact on the ranking of your accommodation.`,
              },
            },
          },
        },
        card: {
          txt: {
            bedroomTxt: "Bedroom",
            bedroomTxtPlural: "Bedrooms",
            bathroomTxt: "Bathroom",
            bathroomTxtPlural: "Bathrooms",
            salonTxt: "Living room",
            salonTxtPlural: "Living rooms",
            priceTxt: "Price",
            nightTxt: "Night",
            nightTxtPlural: "Nights",
            noneTxt: "N/A",
            noteTxt: "Rating",
          },
          notification: {
            addFavorisDone: "Residence added to your favorites",
            removeFavorisDone: "Residence removed from your favorites",
            youNeedConnectTxt: "You must be logged in to access this page",
            errorSendFavorite:
              "An error occurred while adding the residence to your favorites",
          },
        },
        verifyEmail: {
          title: "Activating your account...",
          notification: {
            emailVerified: "Your email address is verified",
            accountAlreadyVerified:
              "Your account is already verified, you can log in",
          },
        },
        profile: {
          checkedTxt: "Verifications completed",
          id: {},
          champs: {
            email: "Email address",
            number: "Phone number",
            id: "ID document",
          },
          piece: {
            infos: "Click here to add your document",
            pieceAcceptsTxt: "We only accept PDF and PNG files",
            completeProfileTxt: "Complete your profile",
            completeProfileTxt1: "Complete your profile",
            addPiceIdentityTxt: "Add your ID document",
            confirm: {
              label: "PDF FILE",
              check: "Active document",
              usedId: "Document in use",
              idInfoClick: "Click on the document to view or modify it",
            },
          },
          photo: {
            noPhoto: "No photo",
            changeTxt: "Change",
          },
          action: {
            resendToken: "Resend confirmation",
          },
          personalInfo: {
            title: "Personal information",
            noData: "No data",
            socialAccount: "Account",
            socialAccountLinked: "linked",
            champs: {
              mail: "Email address",
              password: "Password",
              tel: "Phone number",
              habitation: "Place of residence",
            },
            paiementMethod: "Payment method (host)",
            noPaiementMethod: "No payment method chosen",
          },
          notification: {
            youNeedConnectTxt: "You must be logged in",
            tokenSendWait: "Confirmation token sent",
            tokenSendWaitTxt: "3 minutes before being able to resend",
            errorSendToken:
              "An error occurred while sending the confirmation token",
            fileTypeNotAccepted: "Only png, jpeg and pdf files are accepted",
            profilePhotoTypeNotAccepted:
              "Only png, jpeg, heif files are accepted",
            fileSizeNotAccepted:
              "File size is too large, please reduce the size",
            pieceUploadDone: "Your document has been modified successfully",
            pieceUpload2: "Document uploaded successfully",
            pieceUploadError: "An error occurred while sending your document",
            uploadPhotoConfirm: "Profile photo modified successfully",
            errorMajPhoto: "An error occurred during the update",
            filep1: "The file",
            filep2: "exceeds the maximum allowed size (2.5 MB)",
          },
        },
        profileReservation: {
          title: "My reservations",
          topAction: {
            btnAll: "All",
            btnWaiting: "Pending request",
            btnAcceptByHote: "Accepted by host",
            btnAskValidate: "Request validated",
            inProgressTxt: "In progress",
            btnCancelByYou: "Cancelled by you",
            refused: "Refused",
            waitingRefund: "Awaiting refund",
            refundConfirmed: "Refund confirmed",
          },
          topSection: {
            waitingTxt: "My reservations",
            waitingHoteTxt: "Awaiting host",
            paymentProcessingTxt: "Payment in progress",
            paidTxt: "Payment confirmed",
            waitingPaiementTxt: "Awaiting payment",
            paidConfirm: "Payment confirmed",
            closePanel: "Close panel",
            paiementAcceptTxt: "Payment accepted",
            cancelByClientTxt: "Cancelled by client",
          },
          noData: {
            noDataAllReservation: "You have no current booking",
            noDataWaitingReservation:
              "You have no pending reservation requests",
            noDataAcceptedReservation:
              "You have no accepted reservation requests",
            noDataInProgress: "You have no booking in progress",
            noDataCancelByYouReservation:
              "You have no cancelled reservation requests",
            noDataCancelReservation:
              "You have no cancelled reservation requests",
            noDataWaitingRefund: "You have no pending refund requests",
            noDataRefundConfirmed: "You have no confirmed refund requests",
          },
          infos: {
            refuseBooking: "Refused",
            btnCancel: "Cancel reservation",
            paiementCancelByHote: "Refused by host",
            reservationDoneRsvTxt: "Completed",
          },
          remboursement: {
            remboursementAskTxt: "Refund request",
            noRefundAsk: "No specific reason",
            iChooseOtherResiTxt: "I chose another residence",
            iChangedMyMindTxt: "I changed my mind",
          },
          notification: {
            youNeedConnectTxt: "You must be logged in to access this page",
            confirmRemboursementTxtDone:
              "Refund request submitted successfully",
            confirmRemboursementTxtDone2: "We will contact you by email",
            confirmRemboursementTxtError:
              "An error occurred during the refund request, please try again later",
            refundCanceled: "Refund cancelled",
            refundCanceledError:
              "An error occurred while cancelling the refund, please try again later",
            confirmCancelReservationTxt: "The reservation has been cancelled",
            errorCancelReservationTxt:
              "An error occurred while cancelling the reservation, please try again later",
          },
        },
        notificationPage: {
          title: "Notifications",
          noNotificationTxt: "You have no notifications",
          loadNotificationTxt: "Loading your notifications...",
          notification: {
            youNeedConnectTxt: "You must be logged in to access this page",
            popupBlockedTxt: "The popup blocker prevented opening the new page",
          },
        },
        favorisPage: {
          title: "Favorites",
          loadFavorisTxt: "Loading your favorites...",
          createFirstFavorisTxt: "Create your first favorites list",
          seconPFavorisTxt:
            "When searching, click the heart to save accommodations and experiences that interest you to your favorites.",
          notification: {
            youNeedConnectTxt: "You must be logged in to access this page",
          },
        },
        cookies: {
          p1: "This site uses cookies to ensure you get the best experience. To learn more, read our ",
          p2: "privacy policy",
          btn: "Continue",
        },
        hote: {
          title: "My host space",
          notification: {
            youNeedConnectTxt: "You must be logged in to access this page",
            youNeedVerifyEmailTxt:
              "You must verify your email before accessing this page",
            youNeedHostTxt: "You must be a host to access this page",
            youHaveBeenDisconnectTxt:
              "You have been disconnected. Please log in again.",
          },
          myResidence: {
            residences: "Residences",
            title: "Title",
            stars: "Rating",
            status: "Status",
            bedrooms: "Bedrooms",
            bathrooms: "Bathrooms",
            lastmodif: "Last modifications",
            persons: "People",
            localisation: "Location",
            txts: {
              reasonDelete: "Deletion of residence by host",
              reasonDeleteEn: "Delete residence by host",
              searchPlaceholder: "Search for a residence",
              loadingResidenceData: "Loading in progress",
              noResidenceTxt: "You have no residences",
              noResidenceTxtInfo:
                "Add a residence from the list my residence menu",
            },
            top: {
              title: "My residences",
              deleteResidence: "Delete residence",
              changeStatusResidence: "Change residence status",
            },
            actions: {
              deleteBtn: "Delete",
              seeBtn: "Enable / Disable",
              continueBtn: "Continue adding",
              addResidence: "Add a residence",
            },
            notification: {
              errorDeleteResidenceP1: "Unable to delete residence",
              errorDeleteResidenceP2: "because it is disabled by Trouvechap",
              deleteResidenceDone: "Deletion completed",
              errorDeleteResidence: "An error occurred during deletion",
              errorChangeStatusResidenceP1: "Unable to change residence status",
              errorChangeStatusResidenceP2:
                "because it is disabled by Trouvechap",
              changeStatusResidenceDone: "Status change completed",
              errorChangeStatusResidence:
                "An error occurred during status change",
              noChangeStatus: "No status change made",
            },
          },
          editResidence: {
            chambresTxt: "Bedrooms",
            salonsTxt: "Living rooms",
            sallesDeBainTxt: "Bathrooms",
            waitingTxt: "Pending",
            onlineTxt: "Online",
            echecOnlineTxt: "Failed to go online",
            disableTxt: "Disabled",
            disableTxtP1: "Disabled by Trouvechap",
            tableResidences: "Residences",
            selectResiTxt: "Choose a residence",
            allResidenceTxt: "All residences",
            apercuResidenceTxt: "Residence preview",
            seeCalendarTxt: "View calendar",
            informationResidence: "Residence information",
            personneNumber: "Number of people",
            tableLocalisation: "Location",
            addresResidenceTxt: "Residence address",
            proprietePieces: "Room properties",
            chambersAndSpaces: "Rooms and spaces",
            typeResidenceTxt: "Residence type",
            labelComodities: "Amenities",
            modifierTxt: "Modify",
            voirMoinsTxt: "See less",
            voirToutTxt: "See all",
            cancelConditionTxt: "Cancellation conditions",
            cancelConditionModifDescri:
              "Set the refund rate that applies in case of booking cancellation",
            policyRules: "Rules and policies",
            policyRulesModifDescri: "Modify residence rules and policies",
            activitiesSociales: "Social activities",
            selectAnnonceTxt: "Select a listing",
            modals: {
              loadingMapTxt: "Loading map",
              changerNombrePiecesTxt: "Change number of rooms",
              locationTxt: "Location",
              placeholderTxt: "Enter your address",
              searchInLoadingTxt: "Search in progress...",
              modifierReglesTxt: "Modify residence rules",
              animalAllowedTxt: "Pets allowed",
              smokeAllowedTxt: "Smoking allowed",
              eventAllowedTxt: "Events allowed",
              childsAllowedTxt: "Children allowed",
              modifierAdresseTxt: "Modify residence address",
              paysCountryPlaceholderTxt: "Country",
              villePlaceholderTxt: "City",
              quartierPlaceholderTxt: "District",
              confirmerTxt: "Confirm",
              confirmModifLocationTxt: "First modify your residence location",
              salonTxt: "Living room",
              modifierTypeResidenceTxt: "Modify residence type",
              pasDeRemboursementTxt: "No refund",
              totalMontantTxt: "of total amount",
              choisirDesComoditesTxt: "Choose amenities",
              modifierConditionsTxt: "Modify cancellation conditions",
              choisirDesActivitesTxt: "Choose social activities",
              salonTxtPlural: "Living rooms",
              salleDeBainTxt: "Bathroom",
              salleDeBainTxtPlural: "Bathrooms",
              chambreTxt: "Bedroom",
              chambreTxtPlural: "Bedrooms",
              modifierTxt: "Modify",
              posResidenceTxt: "Residence location",
              addPhotosTxt: "Add photos",
              modifierPhotosTxt: "Modify residence photos",
              changeOrderPhotosTxt:
                "To change photo order, hold and move photos",
              cookiePanelTxt:
                "Modifying a residence already validated by our team puts it back in the validation process before being visible again on Trouvechap.",
              continueBtn: "Continue",
              disableResidenceTxt:
                "This residence has been disabled by our team, contact us for more information about the reason for this deactivation.",
              closeBtn: "Close",
              modifierOccupationTxt: "Modify occupancy",
              partialTxt: "Partial occupancy",
              fullTxt: "Full occupancy",
              confirmModifTxt: "Confirmation",
              confirmModifOccupationTxt: "Confirm occupancy modification",
              confirmModifOccupationTxt2:
                "Confirm modifying the residence to partial residence?",
              confirmModifOccupationTxt3:
                "Confirm modifying the residence to full residence?",
              cancelBtn: "Cancel",
              modifierTitreTxt: "Modify title",
              modifierTitreTxt2: "Modify residence name",
              notificationTxt: "(Any modification notifies Trouvechap)",
              modifierPrixTxt: "Modify price",
              modifierPrixTxt2: "Modify residence price",
              validateBtn: "Validate",
              informationTxt: "Information",
              informationTxtP1:
                "Trouvechap takes 10% commission on each booking, so you will receive",
              informationTxtP2: "for this amount.",
              description: "Modify description",
              description2: "Modify residence description",
              copyDescriptionTxt:
                "Copy description from one of your residences",
              character: "characters",
              descriptionPlaceholder: "Description",
              sectionPlaceholder: "Section",
              section: "Section",
              selectAnnonceTxt: "Select a listing",
              titlePlaceholder: "Title",
              titleSection: "Title",
              addSectionBtn: "Add a section",
              modifDescriptionTxtBtn: "Modify",
            },
            notification: {
              photoErrorFileTxt: "Only png, jpeg, heif files are accepted",
              photoErrorSizeTxt: "File exceeds maximum allowed size (8 MB)",
              max12PhotosTxt: "Maximum 12 images can be uploaded",
              successModifTxt: "Modification completed",
              modifDoneDisableTxt:
                "Residence disabled, it will be visible again after validation by our team",
              errorModifTxt: "Error while modifying the residence",
              errorTitleSection: "Section title must not exceed 70 characters",
              errorDescriptionSection:
                "Section description must not exceed 1000 characters",
            },
          },
          calendar: {
            title: "Calendar",
            txts: {
              reservedTxt: "Reserved",
              horsServiceTxt: "out-of-service",
              previewTxt: "preview",
              cancelTxt: "Cancel",
              basePriceTxt: "Base price",
              noResidenceTxt: "You have no residences",
              noResidenceTxt0:
                "Add a residence from the list my residence menu",
              previewHsTxt: "preview-oos",
              paragraph: "By clicking on dates, you can set specific prices.",
              understandTxt: "I understand",
              cancelHoteTxt: "Cancellation of unavailability by host",
            },
            notification: {
              reductionEditDone: "Discount modified successfully",
              cancelDateDone: "Date selection cancelled",
              reductionAddDone: "Specific period added successfully!",
              periodeDeleteDone: "Period deleted successfully",
              priceEditDone: "Price modified successfully",
              errorSaveReductionTxt: "An error occurred while saving discounts",
              selectFreeDateTxt: "Free date selection",
              selectSecondDateTxt: "Select a second date",
              changeDateRetryTxt: "Change date and try again",
              error: "An error occurred, please try again later",
              errorAddReduction: "An error occurred while adding the discount",
              indisponibiliteEnregistrer: "Unavailability saved",
              indisponibiliteRetirer: "Unavailability removed",
              selectDateCancel: "Date selection cancelled",
              priceWeekendUpdateDone: "Weekend price updated",
              errorPriceWeekendUpdate:
                "An error occurred while updating weekend price",
              discountEditSuccess: "Discount modified successfully",
              errorDiscountPeriod4SP1:
                "This discount must be less than or equal to your discount of",
              discountActiveDone: "Discount activated successfully",
              discountDisableDone: "Discount deactivated successfully",
              notAvailable: "Feature not available",
              notAvailable2:
                "You will be notified when this feature becomes available",
              errorDiscountPeriod4SP2:
                "linked to the following stay duration: per month (4 weeks)",
              errorDiscountPeriod7SP1:
                "This discount must be less than or equal to your discount of",
              errorDiscountPeriod7SP2:
                "linked to the following stay duration: 7 nights",
              errorDiscountPeriod:
                "An error occurred while updating the discount",
            },
            modals: {
              specificPeriodPreview: "Specific period preview",
              editWeekendPrice: "Modify weekend price",
              priceResidenceLabelTxt: "Residence price",
              priceResidenceWeekendTxt: "Friday and Saturday nights",
              btnValidate: "Validate",
              closePanelTxt: "Close panel",
              enableTxt: "Enabled",
              disableTxt: "Disabled",
              writePriceTxt: "Enter price",
              beginDate: "Period start",
              putResidenceOutService: "Put your residence out of service",
              reductionTxt: "Discounts",
              reductionLenghtTxt: "Activate discounts to attract more clients",
              reductionLenghtTxtSeven: "For 7 nights",
              reductionLenghtTxtMonth: "For 28 nights",
              reductionTxt0: "Discount",
              byNight: "per night",
              ajouterBtnTxt: "Add",
              modifierTxt: "Modify",
              weekendPriceTxt: "Custom weekend price",
              selectDatePeriod:
                "Select a second date in the calendar to configure unavailability of your residence for the period.",
              cancelIndispobilityTxt: "Cancel unavailability",
              indisponibilityParagraph:
                "Your residence will be unavailable for bookings during unavailability periods,",
              indisponibilityParagraph2:
                "(current bookings for the period will not be affected)",
              beginDatePeriod: "Start of unavailability",
              endDatePeriod: "End of unavailability",
              endDate: "Period end",
              pricePeriod: "Price for the period",
              deletePeriod: "Delete period",
              closePanel: "Close panel",
              reservationPreview: "Booking preview",
              confirmTxt: "Confirm",
              detailsPeriodOutService: "Out-of-service period details",
              continueTxt: "Continue",
              cancelTxt: "Cancel",
              residenceOneTxt: "Residence",
              selectOption: "Select an option",
              addIndispoTxt: "Add unavailability",
              addReductionTxt: "Add a discount or specific price",
              beginDatePeriodTxt: "Period start",
              endDatePeriodTxt: "Period end",
              pricePeriodTxt: "Price for the period",
              selectDateParagraph:
                "Select a second date in the calendar then enter the price to configure a specific period.",
              pricePeriodParagraph:
                "Your residence will have the above price for bookings made during configured specific periods,",
              pricePeriodParagraph2:
                "(current bookings for the period will not be affected)",
              modifyPriceTxt: "Modify price",
              addPriceTxt: "Add a price",
              pricePeriodNotAffectedTxt:
                "Prices of bookings already made in the period will not be affected",
              priceTxtWrite: "Enter price",
              confirmBtnTxt: "Confirm",
              bookingTxt2:
                "nights or more without taking into account service fees...",
              percentDiscountTxt: "Discount percentage",
              totalAfterReductionTxt: "Total after discount:",
              nightsTxt: "nights",
              forTxt: "For",
              nightOrMoreTxt: "nights or more",
              discountText1: "Discount for bookings of ",
              discountText2: "nights or more.",
              simulationTxt: "Calculation simulation",
              reductionLenghtTxt2: "Activate discounts to attract more clients",
              discountOffTxt: "Discount of",
              bookingTxt1: "Bookings of ",
            },
          },
          booking: {
            title: "Bookings",
            notification: {
              codeNotValid: "This code is not linked to a booking",
              codeValid: "Valid confirmation code",
              notVersement:
                "You must add a default payment method before accepting a booking",
              reservationAcceptByHote:
                "Request accepted, we will contact you when the booking is confirmed by the client",
              versementConfirm: "Payment method confirmed",
              residenceAlreadyBooked:
                "This residence has already been booked for this period",
              error: "An error occurred",
              confirmCancelReservationTxt: "The reservation has been cancelled",
            },
            txts: {
              lastUpdate: "Last update (Today)",
            },
            actions: {
              confirmReservation: "Confirm booking",
            },
            miniModal: {
              title: "Booking confirmation",
              description:
                "Confirm client's arrival at your residence, this will help track your residence and protect you in case of dispute",
              confirmBtn: "Confirm booking",
              actions: {
                acceptReservationTxt: "Accepter",
              },
            },
            content: {
              sorryCantAcceptNow:
                "Sorry, I cannot accept bookings at the moment",
              codeConfirmation: "Confirmation code",
              residenceEnRepairTxt: "The residence is under repair",
              residenceAlreadyBookedTxt:
                "This residence has already been booked for this period",
              topSection: {
                btnAll: "All",
                inWaiting: "Pending request",
                inProgress: "In progress",
                inComing: "Upcoming",
                done: "Completed",
                cancelByClient: "Cancelled by client",
                btnCancel: "Refused",
                btnCancelByYou: "Cancelled by you",
                btnAskValidate: "Request validated",
                btnAccept: "Accepted",
                noData: {
                  noDataAllReservation: "You have no current bookings",
                  noDataWaitingReservation:
                    "You have no pending booking requests",
                  noDataAcceptedReservation:
                    "You have no accepted booking requests",
                  noDataComingReservation:
                    "You have no upcoming booking requests",
                  noDataCancelByYouReservation:
                    "You have no cancelled booking requests",
                  noDataCancelReservation:
                    "You have no cancelled booking requests",
                },
              },
              miniModal: {
                titles: {
                  confirmationAcceptReservationTxt: "Confirm booking",
                  reservationDetailsTxt: "Booking details",
                  motifDeRefusTxt: "Reason for refusal",
                  writeCanceTitle: "Write your reason for refusal",
                  writeCanceTitleTxt: "Write your reason for refusal",
                  versementMethodTxt: "Your payment methods",
                },
                actions: {
                  cancelReservationTxt: "Cancel booking",
                  acceptReservation: "Accept booking",
                  closePanelTxt: "Close panel",
                  cancelledReservationTxt: "Refuse booking",
                },
                motifPlaceHolderTxt: "Write your reason for refusal here",
                txts: {
                  waitingHotelTxt: "Awaiting host",
                  waitingPaiementTxt: "Awaiting payment",
                  closePanelTxt: "Close panel",
                  paiementAcceptTxt: "Payment accepted",
                  paiementCancelTxt: "Cancelled",
                  doneTxt: "Booking completed",
                  paiementCancelByHoteTxt: "Cancelled by host",
                  thisActionNotReversible: "This action is not reversible!",
                  sorryCantAcceptNow:
                    "Sorry, I cannot accept bookings at the moment",
                  residenceEnRepairTxt: "The residence is under repair",
                  residenceAlreadyBookedTxt:
                    "This residence has already been booked for this period",
                  customCancelBookingTxt: "Write a custom cancellation",
                  writeCancelBtnTxt: "Write reason",
                  cancelledReservationTxt: "Refuse booking",
                },
              },
            },
          },
          statistics: {
            sideBar: {
              performances: "Performance",
              noteGlobaleTxt: "Overall rating",
              gainsTxt: "Earnings",
              avisClientTxt: "Client reviews",
              informationsTxt: "Information",
              trafficTxt: "Traffic",
              annulationReservationsTxt: "Booking cancellations",
            },
            noteGlobale: {
              title: "Overall rating",
              lastUpdateTxt: "Last update (Today)",
              percentStars5Txt: "Percentage of 5 stars",
              cumulNoteTxt: "Global cumulative rating",
              globalChartParagraph:
                "Your overall quality average rating will appear here once a guest leaves a comment.",
              labelMonth: {
                1: "Jan",
                2: "Feb",
                3: "Mar",
                4: "Apr",
                5: "May",
                6: "Jun",
                7: "Jul",
                8: "Aug",
                9: "Sep",
                10: "Oct",
                11: "Nov",
                12: "Dec",
              },
            },
            gainsResidence: {
              title: "Earnings",
              lastUpdateTxt: "Last update (Today)",
              selectPeriodeTxt: "Select a period",
              selectResiTxt: "Choose a residence",
              allResidenceTxt: "All residences",
              beneficesTxt: "Recorded profits for the period",
            },
            notification: {
              clientSendMailDoneTxt: "Email sent successfully",
              errorSendMailTxt: "An error occurred while sending the email",
            },
            avisClient: {
              title: "Client reviews",
              lastUpdateTxt: "Last update (Today)",
              allResidenceTxt: "All residences",
              noAvisClientTitle: "Client reviews will appear here",
              noAvisClientP:
                "We will inform you when a client has left comments",
            },
            informationsGenerales: {
              title: "General information",
              consultationsTxtSelect: "Consultations",
              reservationsTxtSelect: "Bookings",
              conversionTxtSelect: "Conversions",
              durreSejourTxtSelect: "Length of stay",
              residenceTxtTable: "Residence",
              tauxAnnulationTxtTable: "Cancellation rate",
              tauxReservationTxtTable: "Booking rate",
              showTableInfoTxt: "Showing 1 to 10",
              lastUpdateTxt: "Last update (Today)",
              detailsTxtGains: "Details",
              consultationLastTxt: "Consultations in the last 30 days",
              newReservationTxt: "New bookings, last 30 days",
              tauxConversionLast: "Conversion rate, last 30 days",
              dureeMoyenneSejour: "Average length of stay",
              trafficTxt: "Traffic",
              noneTxtNote: "N/A",
              annulationReservationsTxt: "Booking cancellations",
            },
            modals: {
              selectAnnonceTxt: "Select a listing",
              periodeSelectTxt: "Select periods",
              repondreCommentaireTxt: "Reply to comment",
              repondrecommentaireParagraph:
                "Your reply will be sent by email to the client, therefore please be courteous and professional",
              repondreTxtBtn: "Reply",
              confirmTxt: "Confirm",
            },
          },
          chartNotAvailableTxt:
            "Chart not available, come back later, we will send you a notification when it becomes available",
        },
        residencePage: {
          head: {
            residence: "Residence",
            bedroom: "bedroom",
            bedroomPlural: "bedrooms",
            bathroom: "bathroom",
            bathroomPlural: "bathrooms",
            descriptionNormal:
              "Trouvechap | Seasonal rentals of residences, villas, and furnished apartments in Côte d'Ivoire",
            description2:
              "Find exceptional accommodations for your stays and activities in Côte d'Ivoire at competitive prices and with complete security.",
          },
          header: {
            prev: "Previous",
            next: "Next",
            picturePlural: "photos",
            heroTitle: {
              noNoteTxt: "N/A",
              shareBtn: "Share",
              saveBtn: "Save",
            },
          },
          hoteLittleInfo: {
            actions: {
              seeMoreTxt: "See more",
            },
            txts: {
              hoteTxt: "Host",
              partialTxt: "Partial",
              fullResidenceTxt: "Entire residence",
              personneTxt: "Person",
            },
            modal: {
              title: "List of rooms",
            },
            bed: "bedroom",
            salon: "living room",
            bathroom: "shower",
            toilet: "toilet",
            bathroom2: "bathroom",
            balcony: "balcony",
            terrace: "terrace",
            kitchen: "kitchen",
            wifi: "wifi",
            garage: "garage",
            office: "office",
            sportRoom: "gym",
            garden: "garden",
            diningRoom: "dining room",
            pool: "pool",
            sauna: "sauna",
            jacuzzi: "jacuzzi",
            gym: "gym",
            parking: "parking",
            elevator: "elevator",
            terrace2: "terrace",
          },
          description: {
            modals: {
              title: "About the accommodation",
              titleSpecific: "Specific periods",
            },
            txts: {
              descriptionTxt: "Description",
              specificPeriodTxt: "Specific periods",
              au: "to",
              nighted: "per night",
              about: "ABOUT",
              showMoreTxt: "See more",
            },
          },
          comodities: {
            title: "Amenities",
            modal: {
              title: "What this accommodation offers",
            },
            txts: {
              showAllTxt: "See all amenities",
            },
            action: {
              showAllTxt: "See all amenities",
            },
          },
          notification: {
            youNeedConnectTxt:
              "You need to be logged in to add a residence to favorites",
            addFavorisDone: "Residence added to favorites",
            errorAddFavoris: "An error occurred while adding to favorites",
            error404:
              "This residence may be under maintenance at the moment, please come back later.",
          },
          apercu: {
            title: "Overview",
            interiorRulesTxt: "House rules",
            socialActivitiesTxt: "Social activities",
            othersTxt: "Others",
            nothingToShow: "No specific interior rules indicated",
            nothingToShow2: "Social activities are prohibited",
          },
          cancelCondition: {
            title: "Cancellation conditions",
            label: "Cancellation",
            notRemboursement: "No refund",
            amountToGiveTxt: "Amount to refund:",
            totalMontantTxt: "of the total amount",
            showAllTxt: "See all conditions",
          },
          reservationPanel: {
            title: "Book",
            txts: {
              chooseDateTxt: "Choose your date.",
              nbDate: "Note: dates in gray are unavailable",
              nightTxt: "Per night",
              night: "Night",
              nightPlural: "Nights",
              reservationAskTitle: "Request a booking",
              infoReservationTxt:
                "You will not be charged until your booking is confirmed",
              feeOfService: "Service fee",
              reduction: "Discount",
            },
            actions: {
              reservationBtn: "Book",
              signalAnnonceTxt: "Report this listing",
            },
            champs: {
              startDate: "Arrival",
              endDate: "Departure",
              persPlaceholder: "Number of people",
            },
            notification: {
              info: "Enter the above fields first",
              youNeedConnect: "You must first have a Trouvechap account",
              errorDate:
                "Fill in the fields indicating your arrival and departure dates",
            },
          },
          commentary: {
            title: "Comments",
            txts: {
              comment: "Comment",
            },
            modals: {
              title: "Write a comment",
              txts: {
                proprieteTxt: "Property",
                emplacementTxt: "Location",
                rapportQualiteTxt: "Value for money",
                assetsTxt: "Amenities",
                communicationTxt: "Communication",
                sayMoreTxt: "Tell us more (optional)",
                proprieteDescriptionTxt: "Was the accommodation clean?",
                emplacementDescriptionTxt:
                  "Is the accommodation easily accessible and close to shops?",
                rapportQualitePrixDescriptionTxt:
                  "Are the cleanliness, location, and comfort offered by this accommodation worth the price?",
                assetsDescriptionTxt:
                  "Is the accommodation well equipped and functional?",
                communicationDescriptionTxt:
                  "Was communication with the host effective?",
              },
              placeholderTxt: "Write your comment here",
            },
            actions: {
              showAllTxt: "Show comments",
              leaveCommentTxt: "Leave a comment",
              sendTxt: "Send",
            },
            notification: {
              notAllow:
                "You cannot comment on this residence until you have stayed there",
              commentSend: "Comment successfully sent",
              commentSend2: "It will be displayed on your next visit",
              errorSend: "Error sending comment",
            },
          },
          logement: {
            title: "Accommodation location",
            infoLocationLater:
              "The exact address of the residence will be revealed after booking",
          },
          mobileReservationPanel: {
            title: "Book",
            signalAnnonceTxt: "Report this listing",
            notification: {
              youNeedConnect:
                "You must first have a Trouvechap account to book",
            },
            modals: {
              title: "Booking request",
              recapTxt: "Summary",
              personNumber: "Number of people",
              confirmToGo: {
                date: "Date",
                night: "Night",
                nightPlural: "Nights",
                person: "Person",
                personPlural: "People",
                normalPrice: "Normal price",
                discount: "Discount",
                discountedPrice: "Price after discount",
                totalPrice: "Total price",
                nextBtnTxt: "Next",
              },
            },
            txts: {
              byNight: "Per Night",
              chooseDateTxt: "Choose your date.",
              infoReservationHoteTxt:
                "You will only be charged when the host has accepted the booking request.",
            },
            actions: {
              reservationBtn: "Request a booking",
              nextBtnTxt: "Next",
            },
          },
        },
        reservationPaiement: {
          header: {
            title: "Proceed to payment",
            secondTitle: "Booking request",
          },
          reservationRequest: {
            title: "Booking request",
          },
          notification: {
            bookingDone: "Booking request successfully completed",
            cantForThisPeriod:
              "You cannot book this residence for this period.",
            periodAlreadyBooked:
              "There is an ongoing booking covering this period or part of it.",
            noReloadPayment: "We do not allow reloading on the payment page.",
            noValidDate: "The booking dates are not valid.",
            noYourResidence: "You cannot book your own residence.",
          },
          modals: {
            buyWith: {
              title: "Pay with",
              facturationTxt: "Billing address",
              choosePaiementMethodTxt: "Choose a payment method",
              champs: {
                name: "First and last name",
                nameError: "Please provide a correct first and last name",
                emailPlaceholderTxt: "Email address",
                emailPlaceholderErrorTxt:
                  "Please provide a correct email address",
                numeroTelephonePlaceholderTxt: "Phone number",
                numeroTelephonePlaceholderErrorTxt:
                  "Please provide a correct phone number preceded by the code +xxx",
                adresseGeoPlaceholderTxt: "Geographical address",
                adresseGeoPlaceholderErrorTxt:
                  "Please provide a correct geographical address",
                villePlaceholderTxt: "City",
                villePlaceholderErrorTxt: "Please provide a correct city",
                codeZipPlaceholderTxt: "Zip code",
                codeZipPlaceholderErrorTxt: "Please provide a correct zip code",
                provincePlaceholderTxt: "Province",
                provincePlaceholderErrorTxt:
                  "Please provide a correct province",
                paysCountryPlaceholderTxt: "Country",
                paysProvincePlaceholderTxt: "Province",
                paysCountryPlaceholderErrorTxt:
                  "Please provide a correct country",
                reglementInfoTxt:
                  "Your stay will only be charged when the host has approved your booking request.",
              },
            },
            bottomCancelConditions: {
              title: "Cancellation conditions",
              txts: {
                notRemboursement: "No refund",
                amountToGiveTxt: "Amount to refund:",
                totalMontantTxt: "of the total amount",
                proceedToPaymentTxt: "Proceed to payment",
                btnAskReservationTxt: "Request a booking",
              },
              miniModal: {
                titleConfirm: "Confirm booking",
                confirmInfoTxt:
                  "You will not be charged now. The host must accept your booking request before proceeding to payment.",
                continueText: "Continue",
                titleSaveBillingTxt: "Save billing information",
                confirmSaveBillingTxt:
                  "You can save your billing information for future bookings.",
                saveAndReserveBillingTxt: "Save and book",
                justReserveTxt: "Book without saving",
                titlePaidWindowTxt: "Secure payment link",
                paidWindowInfoTxt:
                  "Your secure payment link is ready. Please click the button below to be redirected to the payment page.",
                paidWindowInfoTxt2: "The payment link is open for 5 minutes.",
                continuePaidWindowTxt: "Continue to payment",
              },
              reglements: {
                infoReglementTxt1:
                  "By clicking the button below, I accept the following conditions:",
                infoReglementTxt2: "Host's house rules",
                infoReglementTxt3: "Trouvechap's privacy policy",
                infoReglementTxt4: "and I agree that Trouvechap",
                infoReglementTxt5: "charges my payment method",
                infoReglementTxt6: "if I am responsible for damages.",
              },
              conditions: {
                condition1:
                  "Cancellation less than 48 hours before the start of the stay: ",
                condition2:
                  "Cancellation between 48 hours and 1 week before the start of the stay: ",
                condition3:
                  "Cancellation between 1 week and 1 month before the start of the stay: ",
                condition4:
                  "Cancellation between 1 month and 3 months before the start of the stay: ",
                condition5:
                  "Cancellation more than 3 months before the start of the stay: ",
              },
              notification: {
                errorPayment: "Error during payment attempt",
                paymentInProgress:
                  "A payment is already in progress, you can try again in a few moments",
                paymentBlocked:
                  "The payment window was blocked. Please allow pop-ups for this site.",
                paymentError: "An error occurred during the payment attempt",
                waitingPayment: "Please wait while we verify your payment",
                linkExpired: "The payment link has expired. Please try again.",
                paymentDoneReload:
                  "When you have completed your payment, the page will reload automatically.",
                mailConfirmation:
                  "You will receive a confirmation email once your payment is validated",
                nameError: "Please provide a full name",
                telError: "Please provide a correct phone number",
                emailError: "Please provide a correct email address",
                adressError: "Please provide a correct address",
                villeError: "Please provide a correct city",
                codeZipError: "Please provide a correct zip code",
                provinceError: "Please provide a correct province",
                countryError: "Please provide a correct country",
                paiementError: "Please choose a payment method",
              },
            },
            recap: {
              subtotal: "Subtotal",
              total: "Total price",
              totalTxt: "Total",
              night: "Night",
              nightPlural: "Nights",
              goToPayment: "Proceed to payment",
              askReservationTitleTxt: "Booking request",
              reservationDetailsTxt: "Booking details",
              date: "Date",
              personNumber: "Number of people",
              person: "Person",
              personPlural: "People",
              detailsPricetxt: "Price details",
              fraisTxt: "Fees",
              fee: "Service fee",
              tooltip:
                "The fees are used for the proper functioning of Trouvechap and 24/7 support.",
              discount: "Discount",
            },
            side: {
              subtotal: "Subtotal",
              total: "Total price",
              totalTxt: "Total",
              night: "Night",
              nightPlural: "Nights",
              detailsPricetxt: "Price details",
              feeTxt: "Fees",
              feeOfServiceTxt: "Service fee",
              feeInfoTxt:
                "The fees are used for the proper functioning of Trouvechap and 24/7 support.",
              discountTxt: "Discount",
            },
            quit: {
              title: "Do you want to cancel the booking request?",
              nopTxtAvertissement: "No penalties will be applied",
              btnTxt: "Yes cancel",
            },
          },
        },
        notFound: {
          page404infoTxt: "The page you are looking for does not exist.",
          page404btnTxt: "Return to home",
        },
        addResidence: {
          step1: {
            title: "STEP 1",
            label: "Tell us about your accommodation",
            description: `During this step, we will ask you for information about the accommodation you are offering and whether guests can book it in its entirety or if you are only renting a room. We will then ask you to indicate its location and capacity.`,
          },
          step2: {
            label: "Which of the following best describes your accommodation?",
          },
          step3: {
            label: "What type of access is available for your accommodation?",
            resiEntiere: "Entire residence",
            resiEntiereDescription:
              "Guests have the entire residence to themselves.",
            uneChambre: "A room",
            uneChambreDescription:
              "Guests have their own room in the residence and access to shared spaces.",
          },
          step4: {
            label: "Where is your residence located?",
            description: `Indicate the country, city, and neighborhood where your residence is located, this information is important for guests and helps them find your residence more easily.`,
            countryPlaceholder: "Country",
            cityPlaceholder: "City",
            neighborhoodPlaceholder: "Neighborhood",
            caracter: "characters",
            notifcation: {
              errorCountry: "Please choose a country before choosing a city",
              errorCity: "Please choose a city before choosing a neighborhood",
              errorNeighborhood:
                "Please choose a neighborhood before validating",
              errorCaractereSpeciaux: "Special characters are not allowed",
            },
          },
          step4location: {
            label: "Location of your residence",
            description: `Your address is only communicated to travelers once their booking is confirmed.`,
            locationLoading: "Locating...",
            locationBtnTxt: "Locate me",
            useGoogleMapTxt: "Use a Google Map address",
            googleMapTxt: "Google Map address",
            copyTxt:
              "Copy and paste the Google Map address into the field below!",
            exampleTxt: "It looks like: (x.---, y.---)",
            exampleTxt2: "or: https://maps.google.com/?q=x.---,y.---",
            confirmTxt: "Confirm",
            champs: {
              location: "Enter your address",
            },
            notifcation: {
              locationError: "You have refused geolocation",
              locationError2: "Please enable it in your browser settings.",
              locationDone: "Location successful.",
            },
          },
          step5: {
            label: "Provide the main information about your residence.",
            description:
              "You can add more information or modify the existing ones later in your host dashboard.",
            champs: {
              maxPers: "Max number of people",
              room: "Number of rooms",
              bathroom: "Number of bathrooms",
              salon: "Number of living rooms",
            },
          },
          step6: {
            title: "STEP 2",
            label: "Make your listing stand out",
            description: `During this step, you can add some of the amenities offered in your accommodation and at least 5 photos. You can then add a title and a description.`,
          },
          step7: {
            label: "What amenities does your accommodation offer?",
            description: `You can add amenities once your listing is published.`,
          },
          step8: {
            label: "Add some photos of your residence",
            description: `To start, you will need 5 photos. You can add more or make changes later.`,
            infoDrop: `Drag your photos here`,
            leaveDrop: `You can drop here`,
            chooseFromDevice: `Upload from your device`,
            chooseMore: `Choose at least 5 photos`,
          },
          step9: {
            label: "Add some photos of your residence",
            description: `To start, you will need 5 photos. You can add more or make changes later.`,
            tada: "Ta-da! Check your photos",
            dragAndDrop:
              "To change the order of the photos, hold and move the photos",
            addMore: "Add more",
            deleteAll: "Delete all photos",
            titleModalDelete: "Confirmation",
            txtModalDelete: "Are you sure you want to delete all photos?",
            cancelTxt: "Cancel",
            confirmTxt: "Delete",
            deletePhoto: "Delete",
            photoOnCover: "Cover photo",
          },
          step10: {
            label: "Attract guests with a unique title",
            description: `Short titles are usually the most effective. Don't worry, you can always change it later.`,
            caracter: "characters",
          },
          step11: {
            label: "Create a description",
            description: `Add a detailed description of your accommodation and tell your guests what makes it unique.`,
            copyDescription: "Copy the description of one of your residences",
            placeHolderDescription: "Description",
            caracter: "characters",
            addSection: "Add a section",
            required: "required",
            allResidencesTxt: "All residences",
            section: {
              title: "Section",
              section1: "ABOUT",
              section2: "ACCESSIBILITY",
              section3: "CHECK-IN / CHECK-OUT TIME",
            },
            notification: {
              errorTitle: "The section title must not exceed 70 characters",
              errorDescription:
                "The section description must not exceed 1000 characters",
              noPhone:
                "Phone numbers are not allowed in the section description",
              phoneReplace: "The phone number has been replaced with stars",
            },
            modals: {
              selectAnnonceTxt: "Select a listing",
              title: "Confirmation",
              txt: "You can modify your residence once it is published.",
              btnTxt: "Send",
            },
          },
          step12: {
            title: "STEP 3",
            label: "Finish and publish",
            description: `Finally, you choose the booking settings, set your pricing, and publish your listing.`,
          },
          step13: {
            title: "STEP 3",
            label: "Finish and publish",
            description: `Finally, you choose the booking settings, set your pricing, and publish your listing.`,
            description1: `Guests instantly book available days. Only get bookings when you can accommodate by keeping your calendar and availability settings up to date.`,
            description2: `All booking requests will be subject to your validation as a host.`,
            acceptTxt: "I understand, I accept these conditions.",
          },
          step14: {
            label: "Set the price of the residence",
            description: `You can change it at any time. However, Trouvechap recommends setting a competitive price.`,
            paragraph: "Trouvechap takes",
            commission: "10% commission",
            tooltip: {
              commission:
                "This commission is used for the proper functioning of Trouvechap, it is deducted when a guest books your residence and proceeds to payment.",
              eachReservation: "on each booking, you will therefore receive",
              price: "for this amount.",
            },
          },
          step15: {
            label: "Write your house rules",
            description: `Travelers are required to follow your house rules and may be excluded if they cause problems.`,
            titleLabel1: "Events allowed",
            paragraphL1: `Your residence is available for specific social activities, such as parties or events.`,
            titleLabel2: "Smoking allowed",
            titleLabel3: "Pets allowed",
            titleLabel4: "Children allowed",
          },
          step16: {
            label: "Social activities",
            description: `Choose the types of social activities you allow in your residence.`,
            paragraph: `Trouvechap does not ensure that your rules are followed by your guests. You will therefore be in charge of the measures to be taken in case of non-compliance with the rules by your guests.`,
          },
          step17: {
            label: "Cancellation conditions",
            description: `Choose the cancellation conditions that will apply to your guests. These conditions concern the amounts refunded to travelers when they cancel before the start of their stay`,
            noRefund: "No refund",
            totalAmount: "of the total amount",
          },
          step18: {
            label: "Congratulations!",
            description: `Your residence has been submitted for verification by the Trouvechap team and will soon be added to our catalog. You will now switch to Host mode to set your booking settings.`,
          },
          addResidenceTopHeader: {
            helpTxt: "Help",
            saveAndQuitBtn: "Save and quit",
            wouldSaveAndQuit:
              "Do you want to save the progress of your listing and quit?",
            handleQuitWithoutSave: "Quit without saving",
          },
          addResidenceFooter: {
            letsGoTxtAdd: "Let's go",
            continuerTxtAdd: "Continue",
            retourTxtAdd: "Back",
            finishAddTxt: "Finish",
            perfectAdd: "Perfect!",
            nextTxtAdd: "Next",
            continueToHostMode: "Continue to host mode",
          },
          modals: {
            confirmSendResidence: {
              title: "Confirmation",
              txt: "You can modify your residence once it is published.",
              btnTxt: "Send",
              tcLearnBefore:
                "(Trouvechap will review your residence before publishing it)",
              letsGoTxtAdd: "Let's go",
            },
          },
          description: {
            title: "Residence description",
            champs: {
              about: "ABOUT",
              accessibility: "ACCESSIBILITY",
              arrivalTime: "CHECK-IN / CHECK-OUT TIME",
            },
          },
          notification: {
            errorFile1: "The file",
            errorFile2: "exceeds the maximum allowed size (8 MB)",
            errorFile3: "Only files of type png, jpeg, heif are accepted",
            only12Photos: "A maximum of 12 images can be uploaded",
            errorSendPhotos:
              "There was a problem with the images, please try adding them again",
            residenceAdded: "Residence successfully added",
            nameAlreadyTaken: "The residence name is already taken",
            errorAddResidence:
              "An error occurred while adding the residence, please try again",
            errorGiveLocation:
              "Please reconfirm the location of your residence",
            messageList: {
              1: "We take care of everything",
              2: "A little patience",
              3: "Time to check everything",
              4: "It's almost done",
              5: "We are processing your beautiful photos",
              6: "We are checking some sensitive data",
              7: "Don't panic",
              8: "We are almost ready",
              9: "We are checking everything",
              10: "Image optimization in progress",
              11: "Checking photo quality",
              12: "Metadata analysis in progress",
              13: "Adjusting dimensions",
              14: "Data saving in progress",
              15: "Finalizing the upload",
              16: "We are almost there, thank you for your patience",
              17: "We are checking everything",
              18: "Processing in progress, please wait",
              19: "We are working to improve your experience",
              20: "Nothing is impossible, even loading",
              21: "We are doing what is necessary for you",
              22: "Loading in progress, stay with us",
              23: "Your patience is greatly appreciated",
              24: "We are making every pixel perfect",
              25: "One step at a time, we are getting there",
              26: "Perfection takes time, thank you for your understanding",
              27: "We are ensuring everything meets your expectations",
            },
            youNeedConnect: "You must first have a Trouvechap account",
            youNeedCheckerDataEmail:
              "You must verify your email before you can add a residence",
            youNeedIdentityDoc:
              "You must upload your identity document before you can add a residence",
            youNeedPhoneNumber:
              "You must add a phone number before you can add a residence",
          },
        },
      },
    },
    fr: {
      translation: {
        ...oldTranslationFile.fr,
        loading: {
          txt: "Nous préparons tout...",
        },
        stats: {
          menu: {
            visitors: "Visiteurs",
            conversions: "Conversions & réservations",
            reservations: "Sejours & annulations",
            cancellations: "Raisons d'annulation",
            evolutionGainsMonth: "Evolution des gains mensuels",
            evolutionGains: "Comparaison des revenus par période",
          },
          noResidence: "Aucune résidence trouvée",
          search: "Rechercher",
          allResidences: "Toutes les résidences",
          export: "Exporter les résultats",
          year: "Année",
          visitors: {
            title: "Nombre de visiteurs",
            titleExport: "Statistiques des visiteurs",
            miniTitle: "Visiteur",
            miniTitle2: "Visiteurs",
            emp: "Total",
            percentage: "Percentage",
            header1: "Mois",
            header2: "Nombre de visiteurs",
          },
          conversions: {
            title: "Taux de conversion",
            title2: "Nombre total de réservations",
            titleExportConversion: "Statistiques des conversions",
            titleExportReservations: "Statistiques des réservations",
            visitors: "Visiteurs",
            reservations: "Réservations",
            miniVisitors: "Visiteur",
            miniReservations: "Réservation",
            total: "du total",
          },
          reservations: {
            title: "Taux d'annulation",
            title2: "Durée moyenne des séjours",
            titleExportReservations: "Statistiques des séjours et annulations",
            titleExportAnnulations: "Statistiques des annulations",
            nights: "nuitée",
            nights2: "nuitées",
            total: "du total",
            miniReservations: "Réservation",
            miniAnnulations: "Annulation",
            reservations: "Réservations",
            annulations: "Annulations",
          },
          reasonCancellation: {
            title: "Raisons",
            titleExport: "Raisons d'annulation",
            annulation: "annulation",
            other: "Autres",
            header1: "Libellé",
            header2: "pourcentage",
          },
          cancellations: {
            title: "Raisons d'annulation",
            titleExport: "Statistiques des raisons d'annulation",
            annulation: "annulation",
            other: "Autres",
            header1: "Libellé",
            header2: "pourcentage",
          },
          evolutionGainsMonth: {
            title: "Evolution des gains",
            titleExport: "Evolution des gains mensuels",
            header1: "Mois",
            header2: "Gains",
          },
          evolutionGains: {
            croissance: "Taux de croissance",
            titleExport: "Evolution des gains par période",
            header1: "Période",
            header2: "Gain",
          },
          reservationAverage: {
            title: "Revenu moyen",
            titleExport: "Revenu moyen",
            reservation: "Réservation",
            cancellation: "Annulation",
            average: "Moyenne",
            header1: "Libellé",
            header2: "Valeur en %",
          },
        },
        modals: {
          choiceLanguageDevise: {
            selectLangueLabel: "Choisir la langue",
            selectDeviseLabel: "Choisir la devise",
            confirmTxt: "Confirmer",
            francaisName: "Français",
            anglaisName: "Anglais",
          },
          addLessDiv: {
            required: "* Réquis",
          },
          connexionPanel: {
            inscription: {
              title: "Inscrivez-vous!",
              confirmTxt: "Continuer",
              orTxt: "ou",
              otherChoice: "Vous avez déjà un compte",
              connexionTxt: "connexion",
              social: {
                google: "Continuer avec Google",
                facebook: "Continuer avec Facebook",
              },
              champs: {
                mail: "Adresse email",
                telephone: "Numéro de téléphone",
                issueMail: "Veillez renseigner une addresse email correct",
                issueTel: `Veillez renseigner un numéro de téléphone correct précédé de
                l'indicatif +xxx`,
                issueTelDifferent:
                  "Entrez un numero de téléphone correct et different du précedent",
                password: "Mot de passe",
                issuePassword:
                  "8 caractères minimun, une majuscule et un chiffre au moins",
              },
            },
            finish: {
              title: "Inscrivez-vous!",
              champs: {
                lastname: "Nom",
                firstname: "Prénom",
                password: "Mot de passe",
              },
              process: {
                txt: "En poursuivant ce processus, vous acceptez nos",
                condition: "conditions d'utilisation",
                and: "et",
                policy: "politique de confidentialité",
              },
              issuePassword:
                "8 caractères minimun, une majuscule et un chiffre au moins",
              issueNameType: "Veillez renseigner un nom correct",
              btn: "Inscription",
            },
            connexion: {
              title: "Connectez-vous",
              champs: {
                mail: "Adresse email",
                password: "Mot de passe",
              },
              social: {
                google: "Continuer avec Google",
                facebook: "Continuer avec Facebook",
              },
              or: "ou",
              forgetPassword: "Mot de passe oublié ?",
              btn: "Connexion",
              notHaveAccount: "Vous n'avez pas de compte ?",
              inscription: "Inscription",
            },
            forgetPassword: {
              title: "Mot de passe oublié",
              codeReceiveTxt:
                "Saisissez le code que vous avez réçu à l'adresse",
              validityCodeTxt: " celui-ci est valide pendant 5 minutes.",
              champs: {
                placeholderCode: "Code",
                placeholderMail: "Adresse email",
              },
              issueEmailType: "Veillez renseigner une addresse email correct",
              reSendTxt: "Renvoyer",
              enterCode: "Entrez un code correct",
              checkCode: "Verifier le code",
              newAdresseTxtInfo: `Saisissez l'adresse e-mail associée à votre compte. Nous vous
              enverrons un lien par e-mail pour réinitialiser votre mot de passe.`,
              btn: "Envoyer le lien",
            },
            newPassword: {
              title: "Changer le mot de passe",
              description: `Le nouveau mot de passe doit contenir au moins un symbole ou un
              chiffre et comporter au moins 8 caractères et être différent de
              l'ancien.`,
              champs: {
                password: "Mot de passe",
                confirmPassword: "Confirmer le mot de passe",
              },
              issuePassword: "Mot de passe non valide",
              issueConfirmPassword: "Les mot de passe sont différents",
              btn: "Changer le mot de passe",
            },
            notification: {
              connexionDoneTxt: "Connexion effectuée avec succès",
              errorConnexionTxt: "Mot de passe ou email incorrect",
              inscriptionDoneTxt: "Inscription effectuée avec succès",
              blockAccount:
                "Votre compte à été bloqué par Trouvechap, veuillez nous contacter par mail.",
              blockClient:
                "Votre compte à été bloqué par Trouvechap, veuillez nous contacter par mail.",
              paymentMethod:
                "N'oubliez pas de renseigner votre moyen de paiement dans votre profil.",
              socials: {
                googleLogin: "Connexion avec Google réussie",
                googleLoginError: "Échec de la connexion avec Google",
                facebookLogin: "Connexion avec Facebook réussie",
                facebookLoginError: "Échec de la connexion avec Facebook",
              },
              emailAlreadyExist:
                "Un utilisateur avec cet adresse email existe déjà",
              codeSendTxt:
                "Un code de renitialisation de mot de passe vous a été envoyé par mail",
              userNoExistTxt: "Cet utilisateur n'existe pas",
              codeNoValidTxt: "Le code n'est pas valide",
              errorRegistrationTxt:
                "Une erreur est survenue lors de l'inscription",
              tryAgainTxt: "Veuillez réessayer",
              passChanged: "Mot de passe modifié avec succès",
            },
          },
          whatsapp: {
            title: "Contactez l'assistance via Whatsapp",
            description:
              "Vous pouvez nous contacter via Whatsapp pour toute assistance",
            btn: "Contacter",
          },
          verifyEmail: {
            title: "Verification de votre adresse email",
            text1:
              "Votre adresse mail à été vérifiée avec succès, à plus tard sur trouvechap",
            errorTxt:
              "Le token utilisé est invalide, veuillez en demander un autre depuis le menu profil",
          },
          piece: {
            title: "Ma pièce d'identité",
            titleCheck: "Confirmer l'envoi de votre pièce",
            txt: {
              p1: "Votre pièce d'identité est utilisée pour vérifier votre identité et utiliser pleinement les services de notre plateforme.",
              file: "FICHIER PDF",
              p2: "Cliquez ici pour consulter le document",
              p3: "Mettre à jour la pièce",
              p4: "Modifier votre pièce d'identité mettra vos résidences déjà validées en attente de vérification.",
              p5: "Cliquez ici pour verifier le document",
              confirmTxt: "Confirmez-vous l'envoi de votre pièce ?",
            },
            action: {
              confirm: "Confirmer",
              cancel: "Annuler",
            },
          },
          photo: {
            title: "Confirmer le changement de photo pièce",
            confirmPhotoChange:
              "Confirmez-vous le changement de photo de profil ?",

            confirmTxt: "Confirmer",
          },
          nameUpdate: {
            title: "Modifier votre nom et prénom",
            infoTxt:
              "Modifiez votre nom et prénom pour que vos résidences déjà validées soient en attente de vérification.",
            confirmTxt: "Confirmer",
            champs: {
              lastname: "Nom",
              firstname: "Prénom",
            },
            error: {
              issueNameType: "Veillez renseigner un nom correct",
            },
            notification: {
              errorUpdateName: "Une erreur est survenue lors de la mise à jour",
            },
          },
          personalInfo: {
            password: {
              title: "Changer le mot de passe ?",
              description: `Le nouveau mot de passe doit contenir au moins un symbole ou un chiffre et comporter au moins 8 caractères et être différent de l'ancien`,
              error: {
                issuePassword: "Mot de passe non valide",
                samePassword: "Mot de passe non valide ou identique",
              },
              champs: {
                password: "Mot de passe actuel",
                newPassword: "Nouveau mot de passe",
              },
              button: "Changer le mot de passe",
            },
            telephone: {
              title: "Changer le numéro de téléphone ?",
              description:
                "Entrez le numéro de téléphone précédé de l'indicatif",
              button: "Changer le numéro de téléphone",
            },
            habitation: {
              title: "Changer le lieu d'habitation ?",
              description: "Renseignez votre lieu d'habitation correct.",
              champs: {
                habitation: "Nouveau lieu d'habitation",
                error: "Veillez renseigner un lieu d'habitation correct",
              },
              button: "Changer le lieu d'habitation",
            },
            mail: {
              title: "Changer d'adresse mail",
              champs: {
                mail: "Nouvelle adresse mail",
                error: "Veillez renseigner une adresse mail correct",
              },
              button: "Changer l'adresse mail",
            },
            paiementMethod: {
              title: "Vos moyens de versement",
              loading:
                "Actualisation de vos moyens de versement en cours, veuillez patienter...",
              noPaiementMethod:
                "Vous ne disposez d'aucun moyen de versement, veuillez en ajouter en cliquant sur le bouton ci-dessous.",
              comment: "Comment souhaitez vous recevoir votre argent ?",
              oneMethod: "Vous disposez d'un seul moyen de versement.",
              canModify:
                "Vous pouvez modifier le moyen de versement par défaut de vos réservations.",
              default: "Par défaut",
              defaultMethod: "Moyen de versement par défaut",
              addNewMethod: {
                p1: "Ajouter un",
                p2: "nouveau",
                p3: "moyen de versement",
              },
              modals: {
                addNewMethod: {
                  addTitle: "Ajouter un moyen de versement",
                  editTitle: "Modifier le moyen de versement",
                },
                confirmMethod: {
                  title: "Confirmation du moyen de versement",
                  description:
                    "Après confirmation, vous ne pourrez plus modifier le moyen de versement pour cette réservation.",
                  infos: {
                    p1: "gains versés entre",
                    days1: "7 et 14 jours",
                    days2: "2 et 4 jours",
                    p2: "après le début du séjour du client",
                  },
                },
                txts: {
                  bank: "Compte bancaire",
                  temporary: "Versement temporaire",
                  temporaryDescription:
                    "Ce versement sera automatiquement supprimé après fermeture de la page web",
                  bankName: "Nom de la banque",
                  rib: "RIB",
                  versementMethod: "Moyen de versement",
                },
                champs: {
                  fullname: "Nom complet",
                  telephone: "Numéro de téléphone",
                  tel: "Téléphone",
                },
                button: {
                  goDefault: "Mettre par défaut",
                  delete: "Supprimer",
                  save: "Enregistrer",
                  add: "Ajouter",
                  confirm: "Confirmer",
                },
                notification: {
                  editDone: "Moyen de versement modifié",
                  editTemporaryDone: "Moyen de versement temporaire modifié",
                  errorDefaultMethodTemporary:
                    "Vous ne pouvez pas mettre par défaut un moyen de versement temporaire",
                  errorAlreadyDefault:
                    "Cette méthode de versement est déjà par défaut",
                  temporaryMethodAddDone:
                    "Moyen de versement temporaire ajouté",
                  methodAddDone: "Moyen de versement ajouté",
                  methodEditDefaultDone:
                    "Moyen de versement par défaut modifié",
                  deleteDone: "Moyen de versement supprimé",
                  deleteDefaultMethod:
                    "Vous ne pouvez pas supprimer la méthode de versement par défaut",
                  oneMethodError:
                    "Vous ne pouvez pas supprimer votre seul moyen de versement",
                  blockTemporaryAddError:
                    "Vous devez avoir au moins une méthode de versement, pour pouvoir ajouter un versement temporaire",
                  cannotModifyTemporaryStatus:
                    "Vous ne pouvez pas modifier le statut d'un versement temporaire",
                },
              },
            },
            notification: {
              noPaiementMethod: "Aucun moyen de versement choisi",
              motPasseSwitchDone: "Mot de passe modifié avec succès",
              passwordError: "Mot de passe actuel incorrect",
              motPasseSwitchError:
                "Une erreur est survenue lors de la modification du mot de passe",
              confirmPassTel: "Confirmer le code envoyé par SMS",
              phoneAlreadyUsed: "Le numéro de téléphone est déjà utilisé",
              telError:
                "Une erreur est survenue lors de la modification du numéro de téléphone",
              changeLocationDone: "Lieu d'habitation modifié avec succès",
              changeLocationError:
                "Une erreur est survenue lors de la modification du lieu d'habitation",
              mailChangeError1: "Vous ne pouvez pas changer de mail",
              mailChangeError2: "Ce compte est lié à un réseau social",
              confirmCodeMail: "Confirmer le code réçu par mail",
              mailAlreadyUsed: "Cette adresse email est déjà utilisée",
              mailChangeError:
                "Une erreur est survenue lors de la modification de l'adresse mail",
            },
          },
          tokenTelCheck: {
            title: "Code OTP",
            description:
              "Envoyer un code de vérification sur votre numéro de téléphone afin de le verifier",
            secondDescription:
              "Saississez le code OTP reçu par sms pour vérifier votre numéro de téléphone",
            button: {
              sendCode: "Envoyer le code",
            },
            notification: {
              success: "Numéro de téléphone modifié avec succès",
              newNumber: "Le nouveau numéro de téléphone est",
              errorCode: "Code OTP incorrect",
              confirmCode: "Confirmer le code réçu par SMS",
              errorSendCode:
                "Echec lors de l'envoi du code otp, veuillez réessayer plus tard",
            },
          },
          tokenMailCheck: {
            title: "Code OTP",
            description:
              "Saississez le code OTP reçu par mail pour vérifier votre adresse mail",

            notification: {
              success: "Adresse mail modifiée avec succès",
              errorCode: "Code OTP incorrect",
              confirmCode: "Confirmer le code réçu par mail",
              errorSendCode:
                "Echec lors de l'envoi du code otp, veuillez réessayer plus tard",
            },
          },
          booking: {
            titles: {
              versementMethod: "Moyen de versement sélectionné",
              confirmRefund: "Remboursement confirmé",
              cancelRefund: "Annuler la demande",
              details: "Détails de la réservation",
              remboursementAskTxt: "Demande de remboursement",
              cancelReservationTxt: "Annuler la réservation",
              confirmRemboursementTxt: "Confirmer la demande",
              confirmCancelReservation:
                "Confirmez-vous l'annulation de la réservation ?",
            },

            txts: {
              motifCanceledValue1: "Logement non disponible à l'arrivée",
              motifCanceledValue2: "Non-conformité du logement",
              motifCanceledValue3: "Problème de localisation",
              motifCanceledValue4: "Résidence inexistante",
              motifCanceledValue5: "J’ai eu un empêchement",
              motifCanceledValue6: "J’ai eu une mésentente avec l’hôte",
              motifCanceledValue0: "Autre",
              next: "Suivant",
              motifCanceledValue0Placeholder:
                "Ecrivez votre motif d'annulation",
              titleCancelReason:
                "Veuillez choisir un motif d'annulation parmi ceux proposés ci-dessous",
              motifCanceled: "Motif d'annulation",
              titlePersonneTxt: "Nombre de personnes",
              doAskRemboursementTxt:
                "Vous serez remboursé après la validation de Trouvechap.",
              noMotif: "Aucun motif particulier",
              ichooseOtherResiTxt: "J'ai choisi une autre résidence",
              iChangedMyMindTxt: "J'ai changé d'avis",
              clientName: "Client",
              hoteTxt: "Hôte",
              serialNumber: "ID de la réservation",
              residenceLocation: "Localisation de la résidence",
              seeOnGoogleMap: "Voir sur Google Map",
              paymentMethod: "Moyen de versement choisi",
              refundDetailsTitle: "Remboursement details",
              totalRefund: "Total du Remboursement",
              refundDetails:
                "Votre demande de remboursement est en cours d'examen, vous serez notifié une fois le remboursement accepté par Trouvechap",
              refundDetailsAccepted1:
                "Votre demande de remboursement a été confirmé le",
              refundDetailsAccepted2:
                "par Trouvechap. Toutefois vous pouvez contacter le service client pour plus de détails.",
              myCode: "Mon code",
              myCodeDescription:
                "Présentez ce code à votre hôte lors de votre arrivée.",
              personneTxt: "Personne",
              personneTxtPlural: "Personnes",
              arrivedPlaceholderTxt: "Arrivée",
              departPlaceholderTxt: "Départ",
              payment: "Paiement",
              paymentDescription:
                "Un lien de paiement est ouvert celui-ci durera 5 minutes et est en cours de traitement, vous recevrez un email et serez notifié une fois le paiement validée ou annulé.",
              gainsTotal: "Gains totaux",
              refund: {
                title: "Information",
                p1: "Trouvechap a confirmé la demande de remboursement du client le",
                p2: "par Trouvechap. vous receverai une partie de la somme de la reservation selon vos conditions de remboursement. vous serez notifié et contacté par le service client. Pour plus d'information vous pouvez contacter le service Trouvechap.",
                p3: "Le client a demandé un remboursement, celle ci est en cours d'examen (elle respectera vos conditions de remboursement)",
              },
              infoRemboursementWarningTxt:
                "Vous serez remboursé selon les conditions d'annulation de l'hôte au moment de la réservation, veillez à bien les consulter avant de demander un remboursement",
              annulation: "Annulation",
              notRemboursement: "Pas de remboursement",
              amountToGiveTxt: "Montant à rembourser :",
              totalMontantTxt: "du montant total",
              preview: {
                title: "Preview du remboursement",
                p1: "En se basant sur les conditions d'annulation de l'hôte, vous serez remboursé",
                p2: "du montant total donc",
                p3: "de la part de Trouvechap",
              },
              price: {
                subtotal: "Sous-total de base",
                tarifaire: "Sous total tarifaire",
                baseGains: "Gains de base",
                gainsNoReduction: "Gains sans réduction",
                paymentNoReduction: "Paiement sans réduction",
                gainsAfterIncrease: "Gains après augmentation",
                gainsAfterDecrease: "Gains après réduction",
                totalGains: "Gains total",
                totalPayment: "Paiement total",
                paymentWithReduction: "Paiement avec réduction",
                gainsTotauxTxt: "Gains totaux",
              },
              commission: {
                hote: "Trouvechap récupère 10% de commission sur le sous total tarifaire de la réservation",
                client:
                  "Des frais de service sont appliqués pour offrir une protection : annulation, assistance 24/7 et conformité du logement.",
              },
            },
            button: {
              closePanel: "Fermer",
              cancelRefund: "Annuler la demande",
              confirmTxt: "Confirmer",
              cancelReservation: "Annuler la réservation",
              askRemboursement: "Demande de remboursement",
              callHote: "Nous contacter",
            },
          },
          deleteResidence: {
            title: "Etes-vous sûr de vouloir supprimer ces résidences ?",
            description:
              "En supprimant ces résidences, vous supprimerez également toutes les données qui y sont liées. Impossible de revenir en arrière.",
            deleteTxtNumber: "Les résidences suivantes seront supprimées",
            deleteTxtOne: "La résidence suivante sera supprimée",
            btn: "Supprimer",
            notification: {
              deleteResidenceDone: "Suppression effectuée",
              errorDeleteResidence:
                "Une erreur est survenue lors de la suppression",
            },
          },
          changeStatusResidence: {
            titles: {
              changeStatus: "Changer le statut des résidences suivantes",
              changeStatusOne: "Changer le statut de la résidence suivante",
            },
            description1:
              "Les résidences qui sont désactivées ne seront pas visibles par les utilisateurs.",
            description2:
              "(Trouvechap devra valider les résidences avant qu'elles soient à nouveau visibles)",
            btn: "Changer le statut",
          },
          share: {
            title: "Partager",
            description: "Partager sur les réseaux sociaux",
            textMessage: "Bonjour, pourrais je avoir de l'aide ?",
            action: {
              copyLink: "Copier le lien",
              mail: "Adresse mail",
              whatsapp: "Whatsapp",
              sendDirect: "Messages",
            },
            txts: {
              title: "Résidence sur Trouvechap",
              subject: "Résidence de wow sur Trouvechap!",
              body: "Salut,\n\nJ'ai trouvé cette résidence super intéressante sur le site Trouvechap : ",
              textMessage:
                "Salut,\n\nJ'ai trouvé cette résidence super intéressante sur le site Trouvechap : ",
              textMessageWhatsapp:
                "Salut,\n\nJ'ai trouvé cette résidence super intéressante sur le site Trouvechap : ",
              textDirect:
                "Salut,\n\nJ'ai trouvé cette résidence super intéressante sur le site Trouvechap : ",
            },
            notification: {
              shareDone: "Résidence partagée avec succès",
              linkCopied: "Lien copié avec succès",
              errorCopyLink: "Erreur lors de la copie du lien",
              errorShare: "Une erreur est survenue lors du partage",
              linkSend: "Lien envoyé avec succès",
              errorSendLink: "Erreur lors de l'envoi du lien",
              errorShareLink: "Erreur lors du partage du lien",
            },
          },
          galerie: {
            title: "Galerie de la résidence",
            txts: {
              photos: "Photos",
            },
            action: {
              prev: "Precedent",
              next: "Suivant",
              close: "Fermer",
              galeriePhotoTxt: "Galerie photo",
              share: "Partager",
              save: "Enregistrer",
            },
          },
          signalement: {
            label: "Pourquoi souhaitez-vous signaler cette annonce?",
            description: "Cette action ne sera pas partagée à l'hôte.",
            required: "* Réquis",
            notification: {
              alreadySignaled: "Vous avez déjà signalé cette annonce",
              youNeedConnect:
                "Vous devez être connecté pour signaler une annonce",
              signalDone: "Signalement effectué",
              errorSignal: "Une erreur est survenue lors du signalement",
            },
            action: {
              next: "Suivant",
              send: "Envoyer",
            },
            miniModal: {
              title: "Ecrivez votre message",
              placeholder: "Ecrivez votre message ici",
            },
            champs: {
              reason1: "Cette publication est fausse",
              reason2: "Ce n'est pas un endroit réel",
              reason3: "Cette résidence à déjà été postée",
              reason4: "Cette résidence m'appartient",
              reason5: "Autre",
            },
          },
        },
        header: {
          title: "Trouvechap",
          btns: {
            hote: "Mode hôte",
            help: "Aide",
            listResi: "Lister ma résidence",
            listStore: "Voir toutes nos résidences",
            login: "Connexion / Inscription",
            message: "Messages",
            notification: "Notifications",
            fav: "Favoris",
            profile: "Profil",
            booking: "Mes réservations",
            listResidence: "Lister ma résidence",
            disconnect: "Déconnexion",
          },
          notification: {
            needConnectForPage:
              "Vous devez être connecté pour accéder à cette page.",
            soonAvailable: "Fonctionnalité bientôt disponible",
          },
          store: {
            champs: {
              destination: {
                fromMap: "Depuis la carte",
                placeholder: "Où désirez-vous aller?",
              },
              date: {
                from: "Arrivée",
                to: "Départ",
                datePlaceholder: "Date",
              },
              traveler: {
                title: "Nombre de personnes",
                placeholder: "Ajouter des voyageurs",
                personneTxt: "Personne",
                personneTxtPlural: "Personnes",
                menu: {
                  label: "Nombre de personnes",
                  eraseAll: "Tout effacer",
                  validate: "Valider",
                },
              },
            },
            notification: {
              dateError: "Selectionnez une autre date",
              otherDateError:
                "La réservation configurée couvre une période d'indisponibilité de la résidence",
            },
          },
          hote: {
            mesResiMenuTxt: "Mes résidences",
            calendarMenuTxt: "Calendrier",
            reservationMenuTxt: "Réservations",
            statistiquesMenuTxt: "Statistiques",
          },
        },
        footer: {
          links: {
            faq: "FAQ",
            privacyPolicy: "Politique de confidentialité",
            termsAndConditions: "Termes et conditions",
            legalMentions: "Mentions légales",
          },
          share: {
            title: "Partager",
            description: "Partager sur les réseaux sociaux",
            textMessage: "Bonjour, pourrais je avoir de l'aide ?",
          },
          form: {
            title:
              "Recevez les dernières informations sur les nouveaux services et nouveaux produits de Trouvechap.",
            inputPlaceholder: "Entrez votre adresse email",
            btn: "Envoyer",
          },
          notification: {
            addNewsletterDone: "Vous êtes bien inscrit à notre newsletter",
            errorSendNewsletter:
              "Cette adresse email est déjà présente dans notre newsletter",
          },
          rightTxt: "© 2024 Trouvechap. Tous droits réservés.",
        },
        home: {
          hero: {
            title: "Escapade en amoureux ou sortie entre amis?",
            titleBig: "Trouvez la résidence qu'il vous faut",
          },
          spotlightedResidences: {
            title: "Nos résidences les plus visitées",
            seeMore: "Voir plus",
            seeAll: "Voir plus de résidences",
          },
          paiement: {
            title: "Plusieurs moyens de paiement",
            descriptionP1: `Réservez et payez en toute sécurité avec`,
            descriptionP2: `le service qui vous convient`,
          },
          popularResidence: {
            title:
              "Listez votre résidence sur Trouvechap et ouvrez la porte à des revenus locatifs.",
            descriptionP1: `Plus de`,
            descriptionP2: `résidences déjà listées chez nous, alors qu'attendez-vous?`,
            actionBtn: "Lister ma résidence",
            annonces: {
              top: "Gagnez de l'argent avec votre rési",
              bottom: "Résidence Noria Bassam",
            },
          },
          howWork: {
            title: "Comment fonctionne trouvechap ?",
            description: `Trouvez et réservez la résidence dont vous avez besoin avec Trouvechap, c'est super facile !`,
            cards: {
              one: {
                title: `Cherche ta résidence`,
                description: `Parcourez notre liste de plus de 1000 résidences meublées et faciles d'accès et avec des prix très compétitifs.`,
              },
              two: {
                title: `Compare les offres`,
                description: `Affinez vos critères de recherche selon ce que vous désirez avoir dans votre résidence et comparez les différentes offres de notre liste.`,
              },
              three: {
                title: `Réserve celle de ton choix`,
                description: `Satisfait ? Définissez une date et réservez la rési que vous avez choisi simplement et rapidement.`,
              },
            },
          },
          mobileApp: {
            title: `C'est toujours aussi`,
            highlightedTxt: `Chap et facile`,
            titleSub: `avec l'application mobile.`,
            description: `Avec notre application mobile, Trouvez la résidence de vos rêves et réservez-la en quelques instants, où que vous soyez. Fini les longues attentes et les incertitudes.`,
            downloadBtn: {
              soon: "Bientôt disponible, restez connecté !",
              google: "Télécharger sur Google Play",
              appStore: "Télécharger sur App Store",
            },
          },
          contactUs: {
            title: "Nous serons ravis de vous écouter",
            description: `Ecrivez-nous pour toutes vos questions ou préoccupations.`,
            champs: {
              mail: "Adresse email",
              object: "Objet du mail",
              message: "Message",
            },
            btn: "Envoyer",
          },
        },
        store: {
          map: {
            search: "Depuis la carte",
            title: "Trouvez la résidence de vos rêves",

            storeMap: {
              action: {
                showMap: "Afficher la carte",
                closeMap: "Fermer la carte",
              },
              notification: {
                positionSuccess: "Position récupérée avec succès",
                positionError:
                  "Erreur lors de la récupération de votre position",
                positionDenied:
                  "Vous avez refusé la géolocalisation. Veuillez l'activer dans les paramètres de votre navigateur.",
                positionErrorBrowser:
                  "Votre navigateur ne prend pas en charge la géolocalisation. Veuillez l'activer dans les paramètres de votre navigateur.",
                youNeedConnect:
                  "Vous devez être connecté pour accéder à cette page.",
              },
            },
          },
          big: {
            champs: {
              destination: {
                title: "Destination",
                placeholder: "Où désirez-vous aller?",
              },
              from: {
                title: "Arrivée",
                placeholder: "Date",
              },
              to: {
                title: "Départ",
                placeholder: "Date",
              },
              personne: {
                title: "Nombre de personnes",
                placeholder: "Ajouter des voyageurs",
                personneTxt: "Personne",
                personneTxtPlural: "Personnes",
                menu: {
                  label: "Nombre de personnes",
                  eraseAll: "Tout effacer",
                  validate: "Valider",
                },
              },
            },
          },
          little: {
            title: "Saisir une destination",
            day: {
              nightTxt: "Nuit",
              nightTxtPlural: "Nuits",
              placeholder: "Date",
            },
            traveler: {
              title: "Ajouter des voyageurs",
              txt: "Voyageur",
              txtPlural: "Voyageurs",
            },
          },
          panel: {
            mobile: {
              champs: {
                destination: {
                  label: "Destination",
                  placeholder: "Chercher une destination",
                  lastSearch: "Dernières recherches",
                },
                calendar: {
                  title: "Quand voulez-vous voyager ?",
                  notification: {
                    selectOtherDate: "Veillez sélectionner une autre date",
                    indisponibleDate:
                      "La réservation configurée couvre une période d'indisponibilité de la résidence",
                  },
                },
                traveler: {
                  title: "Nombre de personnes",
                },
              },
              action: {
                erase: "Tout effacer",
                search: "Chercher",
              },
            },
          },
          top: {
            btnFilters: "Filtres",
          },
          notification: {
            destinationInputError: "Veillez renseigner une destination",
            addDateInputError:
              "Veillez renseigner une date d'arrivée et de départ",
            addPersonneInputError: "Veillez renseigner un nombre de personnes",
          },
          filters: {
            title: "Filtres",
            range: {
              title: "Fourchette de prix",
              min: "Minimum",
              max: "Maximum",
              night: "Par Nuit",
            },
            room: {
              title: "Nombre de pièces",
              bedroom: {
                bedroomTxt: "Chambre",
                bedroomTxtPlural: "Chambres",
              },
              bathroom: {
                bathroomTxt: "Salle de bain",
                bathroomTxtPlural: "Salles de bain",
              },
              salon: {
                salonTxt: "Salon",
                salonTxtPlural: "Salons",
              },
            },
            occupation: {
              title: "Occupation",
              full: {
                title: "Résidence entière",
                description:
                  "Les clients disposent de la résidence dans son intégralité.",
              },
              shared: {
                title: "Une chambre",
                description:
                  "Les clients ont droit à leur propre chambre dans la résidence et ont accès à des espaces partagés.",
              },
              commodities: {
                title: "Commodités",
                equipments: "Équipements de Base",
                security: "Sécurité et Confort",
                leisure: "Détente et Loisirs",
                convenience: "Praticité et Services",
                other: "Autres",
                social: "Activités sociales",
              },
            },
            social: {
              title: "Activités sociales",
              events: "Événements Festifs en Journée",
              nightEvents: "Événements Festifs en Soirée",
              education: "Apprentissage et Éducation",
              arts: "Activités Artistiques et Créatives",
            },
            activities: {
              title: "Apprentissage et Éducation",
            },
            arts: {
              title: "Activités Artistiques et Créatives",
            },
            residence: {
              title: "Type de résidence",
              home: "Maison",
              appart: "Appartement",
              chalet: "Chalet",
            },
            action: {
              resetAll: "Tout effacer",
              search: "Chercher",
            },
          },
          page: {
            endResult: "Fin des résultats !",
            endResultTxt:
              "N'hésitez pas à essayer d'autres combinaisons de filtres afin de trouver la meilleure résidence pour vous.",
            seeMore: "Voir plus",
            tryMap: "ou essayer via la carte.",
          },
        },
        listPopuResidence: {
          btn: "Lister ma résidence",
          notification: {
            infoNeedConnect: "Vous devez d'abord posseder un compte Trouvechap",
          },
          faq: {
            title: "FAQ",
            description: `Recevez des réponses rapides à toutes vos questions et
            préoccupations concernant Trouvechap. Nous avons des réponses prêtes
            pour vous, que vous soyez un invité, un hôte ou un simple membre.`,
            cards: {
              one: {
                title: "Comment réserver un logement sur Trouvechap ?",
                subtitle:
                  "La réservation du logement se fait de manière simple :",
                one: `Insérez la destination, les dates, ainsi que le nombre de résidents qui séjourneront.`,
                two: `Ajoutez des filtres selon vos exigences (piscine, jacuzzi, possibilité d'y organiser un évènement)`,
                three: `Sélectionnez ensuite le logement que vous désirez`,
                four: `Cliquez sur `,
                four2: "réserver",
                five: `Rentrez ensuite vos coordonnées, puis cliquez sur`,
                five2: "demande de réservation.",
                six: `Une demande est ensuite envoyée à l'hôte. Le paiement pourra s'effectuer lorsque l'hôte aura accepté votre demande.`,
              },
              two: {
                title: "Comment mettre en ligne un logement sur Trouvechap ?",
                description: `Cliquez sur lister ma résidence`,
                one: `Cliquez sur`,
                one2: "lister ma résidence",
                two: `Suivez les différentes étapes`,
                three: `Une notification vous sera envoyée, lorsque l'équipe de Trouvechap aura examiné votre demande.`,
              },
              three: {
                title: "En tant qu'hôte, comment annuler une réservation ?",
                description: `En tant qu'hôte vous pouvez être emmené à annuler une réservation pour diverses raisons, indépendamment de votre volonté. Pour se faire, veuillez contacter l'équipe de Trouvechap par appel, ou par Whatsapp. Cependant, il n'est pas conseillé d'annuler une réservation. Cela pourrait avoir un impact sur le classement de votre logement.`,
              },
            },
          },
        },
        card: {
          txt: {
            bedroomTxt: "Chambre",
            bedroomTxtPlural: "Chambres",
            bathroomTxt: "Salle de bain",
            bathroomTxtPlural: "Salles de bain",
            salonTxt: "Salon",
            salonTxtPlural: "Salons",
            priceTxt: "Prix",
            nightTxt: "Nuit",
            nightTxtPlural: "Nuits",
            noneTxt: "N/A",
            noteTxt: "Note",
          },
          notification: {
            addFavorisDone: "Résidence ajoutée à vos favoris",
            removeFavorisDone: "Résidence retirée de vos favoris",
            youNeedConnectTxt:
              "Vous devez être connecté pour accéder à cette page.",
            errorSendFavorite:
              "Une erreur est survenue lors de l'ajout de la résidence à vos favoris",
          },
        },
        verifyEmail: {
          title: "Activation de votre compte...",
          notification: {
            emailVerified: "Votre adresse mail est vérifiée",
            accountAlreadyVerified:
              "Votre compte est déjà vérifié, vous pouvez vous connecter",
          },
        },
        profile: {
          checkedTxt: "Vérifications effectuées",
          id: {},
          champs: {
            email: "Adresse email",
            number: "Numéro de téléphone",
            id: "Pièce d'identité",
          },
          piece: {
            infos: "Cliquez ici pour ajouter votre pièce",
            pieceAcceptsTxt: "Nous acceptons seulement les PDF et PNG",
            completeProfileTxt: "Complétez votre profil",
            completeProfileTxt1: "Complétez votre profil",
            addPiceIdentityTxt: "Ajoutez votre pièce d'identité",
            confirm: {
              label: "FICHIER PDF",
              check: "Pièce active",
              usedId: "Pièce en cours d'utilisation",
              idInfoClick: "Cliquez sur la pièce pour la voir ou la modifier",
            },
          },
          photo: {
            noPhoto: "Aucune photo",
            changeTxt: "Changer",
          },
          action: {
            resendToken: "Renvoyer la confirmation",
          },
          personalInfo: {
            title: "Informations personnelles",
            noData: "Aucune donnée",
            socialAccount: "Compte",
            socialAccountLinked: "lié",
            champs: {
              mail: "Adresse email",
              password: "Mot de passe",
              tel: "Numéro de téléphone",
              habitation: "Lieu d'habitation",
            },
            paiementMethod: "Moyen de versement (hote)",
            noPaiementMethod: "Aucun moyen de versement choisi",
          },
          notification: {
            youNeedConnectTxt: "Vous devez être connecté",
            tokenSendWait: "Token de confirmation de mail envoyé",
            tokenSendWaitTxt: "3 minutes avant de pouvoir renvoyer",
            errorSendToken:
              "Une erreur est survenue lors de l'envoi du token de confirmation",
            fileTypeNotAccepted:
              "Uniquement les fichiers du type png, jpeg et pdf sont acceptés",
            profilePhotoTypeNotAccepted:
              "Uniquement les fichiers du type png, jpeg, heif sont acceptés",
            fileSizeNotAccepted:
              "La taille du fichier est trop grande, veuillez réduire la taille",
            pieceUploadDone: "Votre pièce a été modifiée avec succès",
            pieceUpload2: "Piece uploadée avec succès",
            pieceUploadError:
              "Une erreur est survenue lors de l'envoi de votre pièce",
            uploadPhotoConfirm: "Photo de profil modifiée avec succès",
            errorMajPhoto: "Une erreur est survenue lors de la mise à jour",
            filep1: "Le fichier",
            filep2: "dépasse la taille maximale autorisée (2,5 Mo)",
          },
        },
        profileReservation: {
          title: "Mes réservations",
          topAction: {
            btnAll: "Tout",
            btnWaiting: "Demande en attente",
            btnAcceptByHote: "Acceptée par l'hôte",
            btnAskValidate: "Demande validée",
            inProgressTxt: "En cours",
            btnCancelByYou: "Annulée par vous",
            refused: "Refusée",
            waitingRefund: "En attente de remboursement",
            refundConfirmed: "Remboursement confirmé",
          },
          topSection: {
            waitingTxt: "Mes réservations",
            waitingHoteTxt: "En attente de l'hôte",
            paymentProcessingTxt: "Paiement en cours",
            paidTxt: "Paiement confirmé",
            waitingPaiementTxt: "En attente de paiement",
            paidConfirm: "Paiement confirmé",
            closePanel: "Fermer le panel",
            paiementAcceptTxt: "Paiement accepté",
            cancelByClientTxt: "Annulée par le client",
          },
          noData: {
            noDataAllReservation: "Vous n'avez aucune réservation en cours",
            noDataWaitingReservation:
              "Vous n'avez effectué aucune demande de réservation en attente",
            noDataAcceptedReservation:
              "Vous n'avez effectué aucune demande de réservation acceptée",
            noDataInProgress: "Vous n'avez aucune réservation en cours",
            noDataCancelByYouReservation:
              "Vous n'avez effectué aucune demande de réservation annulée",
            noDataCancelReservation:
              "Vous n'avez effectué aucune demande de réservation annulée",
            noDataWaitingRefund:
              "Vous n'avez effectué aucune demande de remboursement en attente",
            noDataRefundConfirmed:
              "Vous n'avez effectué aucune demande de remboursement confirmé",
          },
          infos: {
            refuseBooking: "Refusée",
            btnCancel: "Annuler la réservation",
            paiementCancelByHote: "Refusée par l'hôte",
            reservationDoneRsvTxt: "Terminé",
          },
          remboursement: {
            remboursementAskTxt: "Demande de remboursement",
            noRefundAsk: "Aucun motif particulier",
            iChooseOtherResiTxt: "J'ai choisi une autre résidence",
            iChangedMyMindTxt: "J'ai changé d'avis",
          },
          notification: {
            youNeedConnectTxt:
              "Vous devez être connecté pour accéder à cette page",
            confirmRemboursementTxtDone:
              "Demande de remboursement effectuée avec succès",
            confirmRemboursementTxtDone2: "Nous vous contacterons par mail",
            confirmRemboursementTxtError:
              "Une erreur est survenue lors de la demande de remboursement, veillez reessayer plus tard",
            refundCanceled: "Remboursement annulé",
            refundCanceledError:
              "Une erreur est survenue lors de l'annulation du remboursement, veuillez reessayer plus tard",
            confirmCancelReservationTxt: "La reservation a bien été annulé",
            errorCancelReservationTxt:
              "Une erreur est survenue lors de l'annulation de la réservation, veuillez reessayer plus tard",
          },
        },
        notificationPage: {
          title: "Notifications",
          noNotificationTxt: "Vous n'avez aucune notification",
          loadNotificationTxt: "Chargement de vos notifications en cours...",
          notification: {
            youNeedConnectTxt:
              "Vous devez être connecté pour accéder à cette page",
            popupBlockedTxt:
              "Le bloqueur de fenêtres popup a empêché l'ouverture de la nouvelle page",
          },
        },
        favorisPage: {
          title: "Favoris",
          loadFavorisTxt: "Chargement de vos favoris en cours...",
          createFirstFavorisTxt: "Créez votre premiere liste de favoris",
          seconPFavorisTxt:
            "Lors de vos recherches, cliquez sur le cœur pour enregistrer les logements et les expériences qui vous intéressent dans vos favoris.",
          notification: {
            youNeedConnectTxt:
              "Vous devez être connecté pour accéder à cette page",
          },
        },
        cookies: {
          p1: "Ce site utilise des cookies pour vous garantir la meilleure expérience. Pour en savoir plus, consultez notre ",
          p2: "politique de confidentialité",
          btn: "Continuer",
        },
        hote: {
          title: "Mon espace hôte",
          notification: {
            youNeedConnectTxt:
              "Vous devez être connecté pour accéder à cette page",
            youNeedVerifyEmailTxt:
              "Vous devez vérifier votre email avant de pouvoir accéder à cette page",
            youNeedHostTxt: "Vous devez être hôte pour accéder à cette page",
            youHaveBeenDisconnectTxt:
              "Vous avez été déconnecté. Veuillez vous reconnecter.",
          },
          myResidence: {
            residences: "Résidences",
            title: "Titre",
            stars: "Note",
            status: "Statut",
            bedrooms: "Chambres",
            bathrooms: "Salles de bain",
            lastmodif: "Dernières modifications",
            persons: "Personnes",
            localisation: "Localisation",
            txts: {
              reasonDelete: "Suppression de la résidence par l'hôte",
              reasonDeleteEn: "Delete residence by host",
              searchPlaceholder: "Rechercher une résidence",
              loadingResidenceData: "Chargement en cours",
              noResidenceTxt: "Vous n'avez aucune résidence",
              noResidenceTxtInfo:
                "Ajouter une résidence depuis le menu lister ma résidence",
            },
            top: {
              title: "Mes résidences",

              deleteResidence: "Suppression de la résidence",
              changeStatusResidence: "Changement de statut de la résidence",
            },
            actions: {
              deleteBtn: "Supprimer",
              seeBtn: "Activé / Désactivé",
              continueBtn: "Continuer l'ajout",
              addResidence: "Ajouter une résidence",
            },
            notification: {
              errorDeleteResidenceP1: "Impossible de supprimer la résidence",
              errorDeleteResidenceP2: "car elle est désactivé par Trouvechap",
              deleteResidenceDone: "Suppression effectuée",
              errorDeleteResidence:
                "Une erreur est survenue lors de la suppression",
              errorChangeStatusResidenceP1:
                "Impossible de changer le statut de la résidence",
              errorChangeStatusResidenceP2:
                "car elle est désactivé par Trouvechap",
              changeStatusResidenceDone: "Changement de statut effectué",
              errorChangeStatusResidence:
                "Une erreur est survenue lors du changement de statut",
              noChangeStatus: "Aucun changement de statut effectué",
            },
          },
          editResidence: {
            chambresTxt: "Chambres",
            salonsTxt: "Salons",
            sallesDeBainTxt: "Salles de bain",
            waitingTxt: "En attente",
            onlineTxt: "En ligne",
            echecOnlineTxt: "Echec de la mise en ligne",
            disableTxt: "Désactivé",
            disableTxtP1: "Désactivé par Trouvechap",
            tableResidences: "Résidences",
            selectResiTxt: "Choisissez une résidence",
            allResidenceTxt: "Toutes les résidences",
            apercuResidenceTxt: "Aperçu de la résidence",
            seeCalendarTxt: "Voir le calendrier",
            informationResidence: "Informations de la résidence",
            personneNumber: "Nombre de personnes",
            tableLocalisation: "Emplacement",
            addresResidenceTxt: "Adresse de la résidence",
            proprietePieces: "Propriétés des pièces",
            chambersAndSpaces: "Chambres et espaces",
            typeResidenceTxt: "Type de résidence",
            labelComodities: "Commodités",
            modifierTxt: "Modifier",
            voirMoinsTxt: "Voir moins",
            voirToutTxt: "Voir tout",
            cancelConditionTxt: "Conditions d'annulation",
            cancelConditionModifDescri:
              "Définissez le taux de remboursement qui s'applique en cas d'annulation de réservation",
            policyRules: "Règles et politiques",
            policyRulesModifDescri:
              "Modifier les règles et politiques de la résidence",
            activitiesSociales: "Activités sociales",
            selectAnnonceTxt: "Sélectionner une annonce",
            modals: {
              loadingMapTxt: "Chargement de la carte",
              changerNombrePiecesTxt: "Changer le nombre de pièces",
              locationTxt: "Localisation",
              placeholderTxt: "Saisissez votre adresse",
              searchInLoadingTxt: "Recherche en cours ...",
              modifierReglesTxt: "Modifier les règles de la résidence",
              animalAllowedTxt: "Animaux acceptés",
              smokeAllowedTxt: "Autorisation de fumer",
              eventAllowedTxt: "Evènements autorisés",
              childsAllowedTxt: "Enfants autorisés",
              modifierAdresseTxt: "Modifier l'adresse de la résidence",
              paysCountryPlaceholderTxt: "Pays",
              villePlaceholderTxt: "Ville",
              quartierPlaceholderTxt: "Quartier",
              confirmerTxt: "Confirmer",
              confirmModifLocationTxt:
                "Modifier tout d'abord l'emplacement de votre résidence",
              salonTxt: "Salon",
              modifierTypeResidenceTxt: "Modifier le type de la résidence",
              pasDeRemboursementTxt: "Pas de remboursement",
              totalMontantTxt: "du montant total",
              choisirDesComoditesTxt: "Choisir des commodités",
              modifierConditionsTxt: "Modifier les conditions d'annulation",
              choisirDesActivitesTxt: "Choisir des activités sociales",
              salonTxtPlural: "Salons",
              salleDeBainTxt: "Salle de bain",
              salleDeBainTxtPlural: "Salles de bain",
              chambreTxt: "Chambre",
              chambreTxtPlural: "Chambres",
              modifierTxt: "Modifier",
              posResidenceTxt: "Emplacement de la résidence",
              addPhotosTxt: "Ajouter photos",
              modifierPhotosTxt: "Modifier les photos de la résidence",
              changeOrderPhotosTxt:
                "Pour changer l'ordre des photos, maintenez et déplacez les photos",
              cookiePanelTxt:
                "Modifier une résidence déjà validée par notre équipe la remet à nouveau dans le processus de validation avant d'être de nouveau visible sur notre Trouvechap.",
              continueBtn: "Continuer",
              disableResidenceTxt:
                "Cette résidence à été désactivée par notre équipe, contactez-nous pour plus d'informations sur la raison de cette désactivation.",
              closeBtn: "Fermer",
              modifierOccupationTxt: "Modifier l'occupation",
              partialTxt: "Occupation partielle",
              fullTxt: "Occupation complète",
              confirmModifTxt: "Confirmation",
              confirmModifOccupationTxt:
                "Confirmer la modification de l'occupation",
              confirmModifOccupationTxt2:
                "Confirmer la modification de la résidence en résidence partielle ?",
              confirmModifOccupationTxt3:
                "Confirmer la modification de la résidence en résidence complète ?",
              cancelBtn: "Annuler",
              modifierTitreTxt: "Modifier le titre",
              modifierTitreTxt2: "Modifier le nom de la residence",
              notificationTxt: "(Toute modification notifie Trouvechap)",
              modifierPrixTxt: "Modifier le prix",
              modifierPrixTxt2: "Modifier le prix de la residence",
              validateBtn: "Valider",
              informationTxt: "Information",
              informationTxtP1:
                "Trouvechap récupère 10% de commission sur chaque réservation, vous recevrez donc",
              informationTxtP2: "pour ce montant.",
              description: "Modifier la description",
              description2: "Modifier la description de la residence",
              copyDescriptionTxt:
                "Copier la description de l'une de vos résidences",
              character: "caractères",
              descriptionPlaceholder: "Description",
              sectionPlaceholder: "Section",
              section: "Section",
              selectAnnonceTxt: "Sélectionner une annonce",
              titlePlaceholder: "Titre",
              titleSection: "Titre",
              addSectionBtn: "Ajouter une section",
              modifDescriptionTxtBtn: "Modifier",
            },
            notification: {
              photoErrorFileTxt:
                "Uniquement les fichiers du type png, jpeg, heif sont acceptés",
              photoErrorSizeTxt:
                "Le fichier dépasse la taille maximale autorisée (8 Mo)",
              max12PhotosTxt: "12 images au maximum peuvent etre upload",
              successModifTxt: "Modification effectuée",
              modifDoneDisableTxt:
                "Résidence désactivée, elle sera de nouveau visible après validation de notre équipe",
              errorModifTxt: "Erreur lors de la modification de la résidence",
              errorTitleSection:
                "Le titre de la section ne doit pas dépasser 70 caractères",
              errorDescriptionSection:
                "La description de la section ne doit pas dépasser 1000 caractères",
            },
          },
          calendar: {
            title: "Calendrier",
            txts: {
              reservedTxt: "Réservé",
              horsServiceTxt: "hors-service",
              previewTxt: "preview",
              cancelTxt: "Annuler",
              basePriceTxt: "Prix de base",
              noResidenceTxt: "Vous n'avez aucune résidence",
              noResidenceTxt0:
                "Ajouter une résidence depuis le menu lister ma résidence",
              previewHsTxt: "preview-hs",
              paragraph:
                "En cliquant sur les dates, il est possible de définir des prix spécifiques.",
              understandTxt: "J'ai compris",
              cancelHoteTxt: "Annulation de l'indisponibilité par l'hôte",
            },
            notification: {
              reductionEditDone: "Réduction modifiée avec succès",
              cancelDateDone: "Selection de date annulée",
              reductionAddDone:
                "Ajout de periode spécifique effectué avec succès !",
              periodeDeleteDone: "Période supprimée avec succès",
              priceEditDone: "Prix modifié avec succès",
              errorSaveReductionTxt:
                "Une erreur est survenue lors de l'enregistrement des réductions",
              selectFreeDateTxt: "Selection de date libre",
              selectSecondDateTxt: "Selectionnez une seconde date",
              changeDateRetryTxt: "Changer de date et réessayer",

              error: "Une erreur est survenue, veuillez réessayer plus tard",
              errorAddReduction:
                "Une erreur est survenue lors de l'ajout de la réduction",
              indisponibiliteEnregistrer: "Indisponibilité enregistrée",
              indisponibiliteRetirer: "Indisponibilité retirée",
              selectDateCancel: "Selection de date annulée",
              priceWeekendUpdateDone: "Prix du weekend mis à jour",
              errorPriceWeekendUpdate:
                "Une erreur est survenue lors de la mise à jour du prix du weekend",
              discountEditSuccess: "Réduction modifiée avec succès",
              errorDiscountPeriod4SP1:
                "Cette réduction doit être inférieure ou égale à votre réduction de",
              discountActiveDone: "Réduction activée avec succès",
              discountDisableDone: "Réduction désactivée avec succès",
              notAvailable: "Fonctionnalité non disponible",
              notAvailable2:
                "Vous serez notifié dès que cette fonctionnalité sera disponible",
              errorDiscountPeriod4SP2:
                "liée à la durée de séjour suivant: au mois (4 semaines)",
              errorDiscountPeriod7SP1:
                "Cette réduction doit être inférieure ou égale à votre réduction de",
              errorDiscountPeriod7SP2:
                "liée à la durée de séjour suivant: 7 nuits",
              errorDiscountPeriod:
                "Une erreur est survenue lors de la mise à jour de la réduction",
            },
            modals: {
              specificPeriodPreview: "Aperçu de la période spécifique",
              editWeekendPrice: "Modifier le prix pour le weekend",
              priceResidenceLabelTxt: "Prix de la résidence",
              priceResidenceWeekendTxt: "Nuits du vendredi et du samedi",
              btnValidate: "Valider",
              closePanelTxt: "Fermer le panel",
              enableTxt: "Activé",
              disableTxt: "Désactivé",
              writePriceTxt: "Saisissez le prix",
              beginDate: "Debut de période",
              putResidenceOutService: "Mettre votre résidence hors-service",
              reductionTxt: "Réductions",
              reductionLenghtTxt:
                "Activez des réductions pour attirer plus de clients",
              reductionLenghtTxtSeven: "Pour 7 nuits",
              reductionLenghtTxtMonth: "Pour 28 nuits",
              reductionTxt0: "Réduction",
              byNight: "par nuit",
              ajouterBtnTxt: "Ajouter",
              modifierTxt: "Modifier",
              weekendPriceTxt: "Prix du week-end sur mesure",
              selectDatePeriod:
                "Selectionner une seconde date dans le calendrier afin de configurer une indisponibilé de votre résidence sur la période.",
              cancelIndispobilityTxt: "Annuler l'indisponibilé",
              indisponibilityParagraph:
                "Votre résidence sera indisponible pour les réservations pendant les périodes d'indisponibilité,",
              indisponibilityParagraph2:
                "(les réservations en cours sur la période ne seront pas affectées)",
              beginDatePeriod: "Debut d'indisponibilité",
              endDatePeriod: "Fin d'indisponibilité",
              endDate: "Fin de période",
              pricePeriod: "Prix pour la période",
              deletePeriod: "Supprimer la période",
              closePanel: "Fermer le panel",
              reservationPreview: "Aperçu de la réservation",
              confirmTxt: "Confirmer",
              detailsPeriodOutService: "Details période hors-service",
              continueTxt: "Continuer",
              cancelTxt: "Annuler",
              residenceOneTxt: "Résidence",
              selectOption: "Selectionner une option",
              addIndispoTxt: "Ajouter une indisponibilité",
              addReductionTxt: "Ajouter une réduction ou un prix spécifique",
              beginDatePeriodTxt: "Debut de période",
              endDatePeriodTxt: "Fin de période",
              pricePeriodTxt: "Prix pour la période",
              selectDateParagraph:
                "Selectionner une seconde date dans le calendrier puis saisissez le prix afin de configurer une période spécifique.",
              pricePeriodParagraph:
                "Votre résidence aura le prix ci-dessus pour les réservations effectuées les périodes spécifique configurées,",
              pricePeriodParagraph2:
                "(les réservations en cours sur la période ne seront pas affectées)",
              modifyPriceTxt: "Modifier le prix",
              addPriceTxt: "Ajouter un prix",
              pricePeriodNotAffectedTxt:
                "Les prix des réservations déjà effectués dans la période ne seront pas affectées",
              priceTxtWrite: "Saisissez le prix",
              confirmBtnTxt: "Confirmer",
              bookingTxt2:
                "nuits ou plus sans prise en compte des frais de service...",
              percentDiscountTxt: "Pourcentage de reduction",
              totalAfterReductionTxt: "Total après réduction :",
              nightsTxt: "nuits",
              forTxt: "Pour",
              nightOrMoreTxt: "nuits ou plus",
              discountText1: "Réduction pour les réservations de ",
              discountText2: "nuits au plus.",
              simulationTxt: "Simulation de calcul",
              reductionLenghtTxt2:
                "Activez des réductions pour attirer plus de clients",
              discountOffTxt: "Réduction de",
              bookingTxt1: "Réservations de ",
            },
          },
          booking: {
            title: "Réservations",

            notification: {
              codeNotValid: "Ce code n'est pas lié à une réservation",
              codeValid: "Code de confirmation valide",
              notVersement:
                "Vous devez ajouter un moyen de versement par défaut avant d'accepter une réservation",
              reservationAcceptByHote:
                "Demande acceptée, nous vous contacterons lorsque la réservation sera confirmée par le client",
              versementConfirm: "Moyen de versement confirmé",
              residenceAlreadyBooked:
                "Cette résidence à déjà été réservé pour cette période",
              error: "Une erreur est survenue",
              confirmCancelReservationTxt: "La reservation a bien été annulé",
            },
            txts: {
              lastUpdate: "Dernière mise à jour (Aujourd'hui)",
            },
            actions: {
              confirmReservation: "Confirmer la réservation",
            },
            miniModal: {
              title: "Confirmation de la réservation",
              description:
                "Confirmer la venue du client dans votre résidence, cela permettra de tracer votre résidence et de vous protéger en cas de litige",
              confirmBtn: "Confirmer la réservation",
            },
            content: {
              sorryCantAcceptNow:
                "Désolé, je ne peux accepter de réservation pour le moment",
              codeConfirmation: "Code de confirmation",
              residenceEnRepairTxt: "La résidence est en réparation",
              residenceAlreadyBookedTxt:
                "Cette résidence à déjà été réservé pour cette période",
              topSection: {
                btnAll: "Tout",
                inWaiting: "Demande en attente",
                inProgress: "En cours",
                inComing: "À venir",
                done: "Terminé",
                cancelByClient: "Annulée par le client",
                btnCancel: "Refusée",
                btnCancelByYou: "Annulée par vous",
                btnAskValidate: "Demande validée",
                btnAccept: "Acceptée",
                noData: {
                  noDataAllReservation:
                    "Vous n'avez aucune réservation en cours",
                  noDataWaitingReservation:
                    "Vous n'avez effectué aucune demande de réservation en attente",
                  noDataAcceptedReservation:
                    "Vous n'avez effectué aucune demande de réservation acceptée",
                  noDataComingReservation:
                    "Vous n'avez effectué aucune demande de réservation à venir",
                  noDataCancelByYouReservation:
                    "Vous n'avez effectué aucune demande de réservation annulée",
                  noDataCancelReservation:
                    "Vous n'avez effectué aucune demande de réservation annulée",
                },
              },
              miniModal: {
                titles: {
                  confirmationAcceptReservationTxt: "Confirmer la réservation",
                  reservationDetailsTxt: "Détails de la réservation",
                  motifDeRefusTxt: "Motif de refus",
                  writeCanceTitle: "Ecrivez le motif de votre refus",
                  versementMethodTxt: "Vos moyens de versement",
                },
                actions: {
                  cancelReservationTxt: "Annuler la réservation",
                  acceptReservation: "Accepter la réservation",
                  closePanelTxt: "Fermer le panel",
                  cancelledReservationTxt: "Réfuser la réservation",
                },
                motifPlaceHolderTxt: "Ecrivez le motif de votre refus ici",
                txts: {
                  waitingHotelTxt: "En attente de l'hôte",
                  waitingPaiementTxt: "En attente de paiement",
                  closePanelTxt: "Fermer le panel",
                  paiementAcceptTxt: "Paiement accepté",
                  paiementCancelTxt: "Annulé",
                  doneTxt: "Réservation terminée",
                  paiementCancelByHoteTxt: "Annulée par l'hôte",
                  thisActionNotReversible:
                    "Cette action n'est pas reversible !",
                  sorryCantAcceptNow:
                    "Désolé, je ne peux accepter de réservation pour le moment",
                  residenceEnRepairTxt: "La résidence est en réparation",
                  residenceAlreadyBookedTxt:
                    "Cette résidence à déjà été réservé pour cette période",
                  customCancelBookingTxt: "Ecrire une annulation personnalisée",
                  writeCancelBtnTxt: "Ecrire la raison",
                  cancelledReservationTxt: "Réfuser la réservation",
                },
              },
            },
          },
          statistics: {
            sideBar: {
              performances: "Performances",
              noteGlobaleTxt: "Note globale",
              gainsTxt: "Gains",
              avisClientTxt: "Avis clients",
              informationsTxt: "Informations",
              trafficTxt: "Traffic",
              annulationReservationsTxt: "Annulation des réservations",
            },
            noteGlobale: {
              title: "Note globale",
              lastUpdateTxt: "Dernière mise à jour (Aujourd'hui)",
              percentStars5Txt: "Pourcentage de 5 étoiles",
              cumulNoteTxt: "Note cumulée globale",
              globalChartParagraph:
                "Votre note moyenne de qualité globale s'affichera ici dès qu'un invité laissera un commentaire. ",
              labelMonth: {
                1: "Jan",
                2: "Fev",
                3: "Mar",
                4: "Avr",
                5: "Mai",
                6: "Juin",
                7: "Juil",
                8: "Aou",
                9: "Sep",
                10: "Oct",
                11: "Nov",
                12: "Dec",
              },
            },
            gainsResidence: {
              title: "Gains",
              lastUpdateTxt: "Dernière mise à jour (Aujourd'hui)",
              selectPeriodeTxt: "Sélectionnez une période",
              selectResiTxt: "Choisissez une résidence",
              allResidenceTxt: "Toutes les résidences",
              beneficesTxt: "Bénéfices enregistrés sur la période",
            },
            notification: {
              clientSendMailDoneTxt: "Mail envoyé avec succès",
              errorSendMailTxt:
                "Une erreur est survenue lors de l'envoi du mail",
            },
            avisClient: {
              title: "Avis clients",
              lastUpdateTxt: "Dernière mise à jour (Aujourd'hui)",
              allResidenceTxt: "Toutes les résidences",
              noAvisClientTitle: "Les avis clients s'afficheront ici",
              noAvisClientP:
                "Nous vous informerons dès qu'un clients aura laissé des remarques",
            },
            informationsGenerales: {
              title: "Informations générales",
              consultationsTxtSelect: "Consultations",
              reservationsTxtSelect: "Réservations",
              conversionTxtSelect: "Conversions",
              durreSejourTxtSelect: "Durée du séjour",
              residenceTxtTable: "Résidence",
              tauxAnnulationTxtTable: "Taux d'annulation",
              tauxReservationTxtTable: "Taux de réservation",
              showTableInfoTxt: "Affichage de 1 à 10",
              lastUpdateTxt: "Dernière mise à jour (Aujourd'hui)",
              detailsTxtGains: "Détails",
              consultationLastTxt:
                "Consultations au cours des 30 derniers jours",
              newReservationTxt: "Nouvelles réservations, 30 derniers jours",
              tauxConversionLast: "Taux de conversion, 30 derniers jours",
              dureeMoyenneSejour: "Durée moyenne des séjours",
              trafficTxt: "Trafic",
              noneTxtNote: "N/A",
              annulationReservationsTxt: "Annulation des réservations",
            },
            modals: {
              selectAnnonceTxt: "Sélectionner une annonce",
              periodeSelectTxt: "Selectionner les périodes",
              repondreCommentaireTxt: "Répondre au commentaire",
              repondrecommentaireParagraph:
                "Votre réponse sera envoyée par mail au client, de ce fait, veuillez à être courtois et professionnel",
              repondreTxtBtn: "Répondre",
              confirmTxt: "Confirmer",
            },
          },

          chartNotAvailableTxt:
            "Graphique non disponible, revenez plus tard, nous vous enverrons une notification lorsque celui-ci sera disponible",
        },
        residencePage: {
          head: {
            residence: "Résidence",
            bedroom: "chambre",
            bedroomPlural: "chambres",
            bathroom: "salle de bain",
            bathroomPlural: "salles de bain",
            descriptionNormal:
              "Trouvechap | Locations saisonnières de résidences, villa, et appartements meublées en Côte d'ivoire",
            description2:
              "Trouvez des logements d'exceptions pour vos séjours et activités en Côte d'Ivoire à des prix compétitifs et en toute sécurité.",
          },
          header: {
            prev: "Precedent",
            next: "Suivant",
            picturePlural: "photos",
            heroTitle: {
              noNoteTxt: "N/A",
              shareBtn: "Partager",
              saveBtn: "Enregistrer",
            },
          },
          hoteLittleInfo: {
            actions: {
              seeMoreTxt: "Voir plus",
            },
            txts: {
              hoteTxt: "Hôte",
              partialTxt: "Partiel",
              fullResidenceTxt: "Résidence entière",
              personneTxt: "Personne",
            },
            modal: {
              title: "Liste des pièces",
            },
            bed: "chambre",
            salon: "salon",
            bathroom: "douche",
            toilet: "toilette",
            bathroom2: "salle de bain",
            balcony: "balcon",
            terrace: "terrasse",
            kitchen: "cuisine",
            wifi: "wifi",
            garage: "garage",
            office: "bureau",
            sportRoom: "salle de sport",
            garden: "jardin",
            diningRoom: "salle à manger",
            pool: "piscine",
            sauna: "sauna",
            jacuzzi: "jacuzzi",
            gym: "gym",
            parking: "parking",
            elevator: "ascenseur",
            terrace2: "terrasse",
          },
          description: {
            modals: {
              title: "A propos du logement",
              titleSpecific: "Periodes spécifique",
            },
            txts: {
              descriptionTxt: "Description",
              specificPeriodTxt: "Periodes spécifique",
              au: "au",
              nighted: "la nuitée",
              about: "A PROPOS",
              showMoreTxt: "Voir plus",
            },
          },
          comodities: {
            title: "Commodités",
            modal: {
              title: "Ce que propose ce logement",
            },
            txts: {
              showAllTxt: "Voir toutes les commodités",
            },
            action: {
              showAllTxt: "Voir toutes les commodités",
            },
          },
          notification: {
            youNeedConnectTxt:
              "Vous devez être connecté pour ajouter une résidence en favoris",
            addFavorisDone: "Résidence ajoutée en favoris",
            errorAddFavoris:
              "Une erreur est survenue lors de l'ajout en favoris",
            error404:
              "Cette résidence est peut etre en maintenace pour le moment, revenez plus tard.",
          },
          apercu: {
            title: "Aperçu",
            interiorRulesTxt: "Réglement intérieur",
            socialActivitiesTxt: "Activités sociales",
            othersTxt: "Autres",
            nothingToShow:
              "Aucun réglement intérieur spécifique à suivre n'est indiqué",
            nothingToShow2: "Interdit d'organiser des activités sociales",
          },
          cancelCondition: {
            title: "Conditions d'annulation",
            label: "Annulation",
            notRemboursement: "Pas de remboursement",
            amountToGiveTxt: "Montant à rembourser :",
            totalMontantTxt: "du total du montant",
            showAllTxt: "Voir toutes les conditions",
          },
          reservationPanel: {
            title: "Réserver",
            txts: {
              chooseDateTxt: "Choisissez votre date.",
              nbDate: "Nb: les dates en gris sont indisponibles",
              nightTxt: "Par nuit",
              night: "Nuit",
              nightPlural: "Nuits",
              reservationAskTitle: "Demander une réservation",
              infoReservationTxt:
                "Vous ne serez pas débité avant la confirmation de votre réservation",
              feeOfService: "Frais de service",
              reduction: "Reduction",
            },
            actions: {
              reservationBtn: "Réserver",
              signalAnnonceTxt: "Signaler cette annonce",
            },
            champs: {
              startDate: "Arrivée",
              endDate: "Départ",
              persPlaceholder: "Nombre de personne",
            },
            notification: {
              info: "Entrez d'abord les ci-dessus",
              youNeedConnect:
                "Vous devez d'abord posseder un compte Trouvechap",
              errorDate:
                "Remplissez les champs indiquant votre date d'arrivée et de départ",
            },
          },
          commentary: {
            title: "Commentaires",
            txts: {
              comment: "Commentaire",
            },
            modals: {
              title: "Rédiger un commentaire",
              txts: {
                proprieteTxt: "Propriété",
                emplacementTxt: "Emplacement",
                rapportQualiteTxt: "Rapport qualité/prix",
                assetsTxt: "Commodités",
                communicationTxt: "Communication",
                sayMoreTxt: "Dites-nous en plus (optionnel)",
                proprieteDescriptionTxt: "Le logement était-il propre ?",
                emplacementDescriptionTxt:
                  "Est-ce que le logement est facilement accessible et proche des commerces ?",

                rapportQualitePrixDescriptionTxt:
                  "La propreté, l'emplacement, ainsi que le confort offerts par ce logement sont-ils à la hauteur du prix ?",
                assetsDescriptionTxt:
                  "Est-ce que le logement est bien équipé et fonctionnel ?",
                communicationDescriptionTxt:
                  "La communication avec l'hôte était-elle efficace ?",
              },
              placeholderTxt: "Ecrivez votre commentaire ici",
            },
            actions: {
              showAllTxt: "Afficher les commentaires",
              leaveCommentTxt: "Laisser un commentaire",
              sendTxt: "Envoyer",
            },
            notification: {
              notAllow:
                "Vous ne pouvez pas commenter cette résidence, avant d'y avoir séjourné",
              commentSend: "Commentaire envoyé avec succès",
              commentSend2: "Celui ci sera affiché a votre prochaine venue",
              errorSend: "Erreur lors de l'envoi du commentaire",
            },
          },
          logement: {
            title: "Localisation du logement",
            infoLocationLater:
              "L'adresse exacte de la résidence vous sera dévoilée après réservation",
          },
          mobileReservationPanel: {
            title: "Réserver",
            signalAnnonceTxt: "Signaler cette annonce",
            notification: {
              youNeedConnect:
                "Vous devez d'abord posseder un compte Trouvechap pour réserver",
            },
            modals: {
              title: "Demande de réservation",
              recapTxt: "Récapitulatif",
              personNumber: "Nombre de personnes",
              confirmToGo: {
                date: "Date",
                night: "Nuit",
                nightPlural: "Nuits",
                person: "Personne",
                personPlural: "Personnes",
                normalPrice: "Prix normal",
                discount: "Réduction",
                discountedPrice: "Prix après réduction",
                totalPrice: "Prix total",
                nextBtnTxt: "Suivant",
              },
            },
            txts: {
              byNight: "Par Nuit",
              chooseDateTxt: "Choisissez votre date.",
              infoReservationHoteTxt:
                "Vous serez débités uniquement lorsque l'hôte aura accepté la demande de réservation.",
            },
            actions: {
              reservationBtn: "Demander une réservation",
              nextBtnTxt: "Suivant",
            },
          },
        },
        reservationPaiement: {
          header: {
            title: "Passer au paiement",
            secondTitle: "Demande de réservation",
          },
          reservationRequest: {
            title: "Demande de réservation",
          },
          notification: {
            bookingDone: "Demande de réservation effectuée avec succès",
            cantForThisPeriod:
              "Vous ne pouvez pas réserver cette résidence pour cette periode.",
            periodAlreadyBooked:
              "Il y a une réservation en cours couvrant cette période ou une partie de celle-ci.",
            noReloadPayment:
              "Nous ne permettons pas un rechargement sur la page de paiement.",
            noValidDate: "Les dates de réservation ne sont pas valides.",
            noYourResidence:
              "Vous ne pouvez pas réserver votre propre résidence.",
          },
          modals: {
            buyWith: {
              title: "Payer avec",
              facturationTxt: "Adresse de facturation",
              choosePaiementMethodTxt: "Choissisez un moyen de paiement",
              champs: {
                name: "Nom et prénoms",
                nameError: "Veillez renseigner un nom et prénoms correct",
                emailPlaceholderTxt: "Adresse email",
                emailPlaceholderErrorTxt:
                  "Veillez renseigner une adresse email correct",
                numeroTelephonePlaceholderTxt: "Numéro de téléphone",
                numeroTelephonePlaceholderErrorTxt:
                  "Veillez renseigner un numéro de téléphone correct précédé de l'indicatif +xxx",
                adresseGeoPlaceholderTxt: "Adresse géographique",
                adresseGeoPlaceholderErrorTxt:
                  "Veillez renseigner une adresse géographique correct",
                villePlaceholderTxt: "Ville",
                villePlaceholderErrorTxt:
                  "Veillez renseigner une ville correct",
                codeZipPlaceholderTxt: "Code zip",
                codeZipPlaceholderErrorTxt:
                  "Veillez renseigner un code zip correct",
                provincePlaceholderTxt: "Province",
                provincePlaceholderErrorTxt:
                  "Veillez renseigner une province correct",
                paysCountryPlaceholderTxt: "Pays",
                paysProvincePlaceholderTxt: "Province",
                paysCountryPlaceholderErrorTxt:
                  "Veillez renseigner un pays correct",
                reglementInfoTxt:
                  "Le règlement de votre séjour se fera uniquement lorsque l'hôte aura approuvée votre demande de réservation.",
              },
            },
            bottomCancelConditions: {
              title: "Conditions d'annulation",
              txts: {
                notRemboursement: "Pas de remboursement",
                amountToGiveTxt: "Montant à rembourser :",
                totalMontantTxt: "du total du montant",
                proceedToPaymentTxt: "Procéder au paiement",
                btnAskReservationTxt: "Demander une réservation",
              },
              miniModal: {
                titleConfirm: "Confirmer la réservation",
                confirmInfoTxt:
                  "Vous ne serez pas débité maintenant. L'hote devra accepter votre demande de réservation avant de procéder au paiement.",
                continueText: "Continuer",
                titleSaveBillingTxt: "Enregistrer les informations",
                confirmSaveBillingTxt:
                  "Vous pouvez sauvegarder vos informations de facturation pour les prochaines réservations.",
                saveAndReserveBillingTxt: "Enregistrer et réserver",
                justReserveTxt: "Réserver sans enregistrer",
                titlePaidWindowTxt: "Lien sécurisé de paiement",
                paidWindowInfoTxt:
                  "Votre lien de paiement sécurisé est prêt. Veuillez cliquer sur le button ci-dessous pour être redirigé vers la page de paiement.",
                paidWindowInfoTxt2:
                  "Le lien de paiement est ouvert pendant 5 minutes.",
                continuePaidWindowTxt: "Continuer vers le paiement",
              },
              reglements: {
                infoReglementTxt1:
                  "En cliquant sur le bouton ci-dessous, j'accepte les conditions suivantes :",
                infoReglementTxt2: "Règlement intérieur de l'hôte",
                infoReglementTxt3: "Politique de confidentialité de Trouvechap",
                infoReglementTxt4: "et je donne mon accord pour que Trouvechap",
                infoReglementTxt5: "débite mon mode de paiement",
                infoReglementTxt6: "si je suis responsable de dommages.",
              },
              conditions: {
                condition1:
                  "Annulation moins de 48 heures avant le début du séjour : ",
                condition2:
                  "Annulation entre 48h et 1 semaine avant le début du séjour : ",
                condition3:
                  "Annulation entre 1 semaine et 1 mois avant le début du séjour : ",
                condition4:
                  "Annulation entre 1 mois et 3 mois avant le début du séjour : ",
                condition5:
                  "Annulation plus de 3 mois avant le début du séjour : ",
              },
              notification: {
                errorPayment: "Erreur lors de la tentative de paiement",
                paymentInProgress:
                  "Un paiement est déjà en cours, vous pourrez réessayer dans quelques instants",
                paymentBlocked:
                  "La fenêtre de paiement a été bloquée. Veuillez autoriser les fenêtres contextuelles pour ce site.",
                paymentError:
                  "Une erreur est survenue lors de la tentative de paiement",
                waitingPayment:
                  "Veillez patienter pendant que nous vérifions votre paiement",
                linkExpired:
                  "Le lien de paiement à expiré. Veuillez réessayer.",
                paymentDoneReload:
                  "Lorsque vous aurez terminé votre paiement, la page se rechargera automatiquement.",
                mailConfirmation:
                  "Vous recevrez un mail de confirmation une fois votre paiement validé",
                nameError: "Veuillez renseigner un nom complet",
                telError: "Veuillez renseigner un numéro de téléphone correct",
                emailError: "Veuillez renseigner une adresse email correct",
                adressError: "Veuillez renseigner une adresse correct",
                villeError: "Veuillez renseigner une ville correct",
                codeZipError: "Veuillez renseigner un code zip correct",
                provinceError: "Veuillez renseigner une province correct",
                countryError: "Veuillez renseigner un pays correct",
                paiementError: "Veuillez choisir un moyen de paiement",
              },
            },
            recap: {
              subtotal: "Sous total",
              total: "Prix total",
              totalTxt: "Total",
              night: "Nuit",
              nightPlural: "Nuits",
              goToPayment: "Passer au paiement",
              askReservationTitleTxt: "Demande de réservation",
              reservationDetailsTxt: "Détails de réservation",
              date: "Date",
              personNumber: "Nombre de personnes",
              person: "Personne",
              personPlural: "Personnes",
              detailsPricetxt: "Détails du prix",
              fraisTxt: "Frais",
              fee: "Frais de service",
              tooltip:
                "Les frais servent au bon fonctionnement de trouvechap et le support 24H/24H.",
              discount: "Reduction",
            },
            side: {
              subtotal: "Sous total",
              total: "Prix total",
              totalTxt: "Total",
              night: "Nuit",
              nightPlural: "Nuits",
              detailsPricetxt: "Détails du prix",
              feeTxt: "Frais",
              feeOfServiceTxt: "Frais de service",
              feeInfoTxt:
                "Les frais servent au bon fonctionnement de trouvechap et le support 24H/24H.",
              discountTxt: "Reduction",
            },
            quit: {
              title: "Voulez-vous annuler la demande de reservation?",
              nopTxtAvertissement: "Aucune penalités ne sera appliquée",
              btnTxt: "Oui annuler",
            },
          },
        },
        notFound: {
          page404infoTxt: "La page que vous recherchez n'existe pas.",
          page404btnTxt: "Retourner à l'accueil",
        },
        addResidence: {
          step1: {
            title: "ETAPE 1",
            label: "Parlez-nous de votre logement",
            description: `Au cours de cette étape, nous allons vous demander des
        informations sur le logement que vous proposez et si les clients
        pourront le réserver dans son intégralité ou si vous ne louez
              qu'une chambre. Nous vous demanderons ensuite d'indiquer son
              emplacement et sa capacité d'accueil.`,
          },
          step2: {
            label:
              "Parmi les propositions suivantes, laquelle décrit le mieux votre logement ?",
          },
          step3: {
            label: "Quel type d'accès est disponible pour votre logement ?",
            resiEntiere: "Résidence entière",
            resiEntiereDescription:
              "Les clients disposent de la résidence dans son intégralité.",
            uneChambre: "Une chambre",
            uneChambreDescription:
              "Les clients ont droit à leur propre chambre dans la résidence et ont accès à des espaces partagés.",
          },
          step4: {
            label: "Où est située votre résidence ?",
            description: `Indiquez le pays, la ville et le quartier où se situe votre
              résidence, ces informations sont importantes pour les clients et
              leur permettent de trouver votre résidence plus facilement.`,
            countryPlaceholder: "Pays",
            cityPlaceholder: "Ville",
            neighborhoodPlaceholder: "Quartier",
            caracter: "caractères",
            notifcation: {
              errorCountry:
                "Veuillez choisir un pays avant de choisir une ville",
              errorCity:
                "Veuillez choisir une ville avant de choisir un quartier",
              errorNeighborhood:
                "Veuillez choisir un quartier avant de valider",
              errorCaractereSpeciaux:
                "Les caractères spéciaux ne sont pas autorisés",
            },
          },
          step4location: {
            label: "Localisation de votre résidence",
            description: `Votre adresse est uniquement communiquée aux voyageurs une fois
                leur réservation effectuée.`,
            locationLoading: "Localisation en cours...",
            locationBtnTxt: "Me localiser",
            useGoogleMapTxt: "Utiliser une adresse google map",
            googleMapTxt: "Adresse Google Map",
            copyTxt:
              "Copier coller l'adresse google map, dans le champ ci-dessous !",
            exampleTxt: "Celle ci ressemble à : (x.---, y.---)",
            exampleTxt2: "ou à : https://maps.google.com/?q=x.---,y.---",
            confirmTxt: "Confirmer",
            champs: {
              location: "Saisissez votre adresse",
            },
            notifcation: {
              locationError: "Vous avez refusé la géolocalisation",
              locationError2:
                "Veuillez l'activer dans les paramètres de votre navigateur.",
              locationDone: "Localisation réussie.",
            },
          },
          step5: {
            label:
              "Donnez les informations principales concernant votre résidence.",
            description:
              "Vous pourrez ajouter d'autres informations ou modifier celles présentes plus tard dans votre dashboard hote.",
            champs: {
              maxPers: "Nombre de personnes max",
              room: "Nombre de chambres",
              bathroom: "Nombre de salles de bain",
              salon: "Nombre de salons",
            },
          },
          step6: {
            title: "ETAPE 2",
            label: "Faites sortir votre annonce du lot",
            description: `Au cours de cette étape, vous pourrez ajouter certains des
            équipements proposés dans votre logement et au moins 5 photos.
            Vous pourrez ensuite ajouter un titre et une description.`,
          },
          step7: {
            label: "Quelles sont les commodités offertes par votre logement ?",
            description: `Vous pourrez ajouter des équipements une fois votre annonce
        publiée.`,
          },
          step8: {
            label: "Ajoutez quelques photos de votre résidence",
            description: `Pour commencer, vous aurez besoin de 5 photos. Vous pourrez en
        ajouter d'autres ou faire des modifications plus tard.`,
            infoDrop: `Faites glisser vos photos ici`,
            leaveDrop: `Vous pouvez lacher ici`,
            chooseFromDevice: `Télécharger depuis votre appareil`,
            chooseMore: `Choisissez au moins 5 photos`,
          },
          step9: {
            label: "Ajoutez quelques photos de votre résidence",
            description: `Pour commencer, vous aurez besoin de 5 photos. Vous pourrez en
        ajouter d'autres ou faire des modifications plus tard.`,
            tada: "Ta-da! Vérifiez vos photos",
            dragAndDrop:
              "Pour changer l'ordre des photos, maintenez et déplacez les photos",
            addMore: "Ajouter plus",
            deleteAll: "Supprimer toutes les photos",
            titleModalDelete: "Confirmation",
            txtModalDelete:
              "Êtes-vous certain de vouloir supprimer toutes les photos ?",
            cancelTxt: "Annuler",
            confirmTxt: "Supprimer",
            deletePhoto: "Supprimer",
            photoOnCover: "Photo en couverture",
          },
          step10: {
            label: "Attirez les clients avec un titre unique",
            description: `Les titres courts sont généralement les plus efficaces. Ne vous
            inquiétez pas, vous pourrez toujours le modifier plus tard.`,
            caracter: "caractères",
          },
          step11: {
            label: "Créez une description",
            description: `Ajoutez une description détaillée de votre logement et dites à
                vos clients ce qui le rend unique.`,
            copyDescription: "Copier la description de l'une de vos résidences",
            placeHolderDescription: "Description",
            caracter: "caractères",
            addSection: "Ajouter une section",
            required: "réquis",
            allResidencesTxt: "Toutes les résidences",
            section: {
              title: "Section",
              section1: "A PROPOS",
              section2: "ACCESSIBILITE",
              section3: "HEURE D'ARRIVEE / DEPART",
            },
            notification: {
              errorTitle:
                "Le titre de la section ne doit pas dépasser 70 caractères",
              errorDescription:
                "La description de la section ne doit pas dépasser 1000 caractères",
              noPhone:
                "Les numéros de téléphone sont interdits dans la description de la section",
              phoneReplace:
                "Le numéro de téléphone à été remplacés par des étoiles",
            },
            modals: {
              selectAnnonceTxt: "Selectionner une annonce",
              title: "Confirmation",
              txt: "Vous pouvez modifier votre résidence une fois celle-ci publiée.",
              btnTxt: "Envoyer",
            },
          },
          step12: {
            title: "ETAPE 3",
            label: "Terminez et publiez",
            description: `Enfin, vous choisissez les paramètres de réservation, définissez
            votre tarification et publiez votre annonce.`,
          },
          step13: {
            title: "ETAPE 3",
            label: "Terminez et publiez",
            description: `Enfin, vous choisissez les paramètres de réservation, définissez
            votre tarification et publiez votre annonce.`,
            description1: `Les clients réservent instantanément les jours disponibles.
            N'obtenez des réservations que lorsque vous pouvez accueillir en
            gardant votre calendrier et vos paramètres de disponibilité à
            jour.`,
            description2: `Toutes demandes de réservation sera soumise à une validation de
            votre part en tant qu'hôte.`,
            acceptTxt: "J'ai compris, j'accepte ces conditions.",
          },
          step14: {
            label: "Fixez le prix de la résidence",
            description: `Vous pouvez le modifier à tout moment. Cependant Trouvechap vous
            recommande de fixer un prix compétitif.`,
            paragraph: "Trouvechap récupère",
            commission: "10% de commission",
            tooltip: {
              commission:
                "Cette commission est utilisée pour le bon fonctionnement de Trouvechap, elle est déduite lorsqu'un client réserve votre résidence et passe au paiement.",
              eachReservation: "sur chaque réservation, vous recevrez donc",
              price: "pour ce montant.",
            },
          },
          step15: {
            label: "Rédigez votre règlement intérieur",
            description: `Les voyageurs sont tenus de respecter votre règlement intérieur et peuvent être exclus s'ils causent des problèmes.`,
            titleLabel1: "Événements autorisés",
            paragraphL1: `Votre résidence est disponible pour des activités sociales
        spécifiques, comme des fêtes ou des événements.`,
            titleLabel2: "Autorisation de fumer",
            titleLabel3: "Animaux acceptés",
            titleLabel4: "Enfants acceptés",
          },
          step16: {
            label: "Activités sociales",
            description: `Choisissez les types d'activités sociales que vous autorisez dans
              votre résidence.`,
            paragraph: `Trouvechap n'assure pas le respect de vos règles par vos
            clients. Vous serez donc en charge des mesures à adopter face au
            non-respect des règles par vos clients.`,
          },
          step17: {
            label: "Conditions d'annulation",
            description: `Choisissez les conditions d'annulation qui s'appliqueront à vos
              clients. Ces conditions concernent les montants remboursés aux
              voyageurs, lorsqu'ils annulent avant le début de leurs séjours`,
            noRefund: "Pas de remboursement",
            totalAmount: "du montant total",
          },
          step18: {
            label: "Félicitations !",
            description: `Votre résidence à été soumise à une vérification par l'équipe de
            Trouvechap et sera bientôt ajoutée à notre catalogue. Vous allez
            maintenant basculer en mode Hôte pour régler vos paramètres de
            réservations.`,
          },
          addResidenceTopHeader: {
            helpTxt: "Aide",
            saveAndQuitBtn: "Enregistrer et quitter",
            wouldSaveAndQuit:
              "Voulez pouvez enregistrer la progression de votre annonce et quitter ?",
            handleQuitWithoutSave: "Quitter sans enregistrer",
          },
          addResidenceFooter: {
            letsGoTxtAdd: "C'est parti",
            continuerTxtAdd: "Continuer",
            retourTxtAdd: "Retour",
            finishAddTxt: "Terminer",
            perfectAdd: "Parfait !",
            nextTxtAdd: "Suivant",
            continueToHostMode: "Continuer vers le mode hôte",
          },
          modals: {
            confirmSendResidence: {
              title: "Confirmation",
              txt: "Vous pouvez modifier votre résidence une fois celle-ci publiée.",
              btnTxt: "Envoyer",
              tcLearnBefore:
                "(Trouvechap etudiera votre résidence avant de la publier)",
              letsGoTxtAdd: "C'est parti",
            },
          },
          description: {
            title: "Description de la résidence",
            champs: {
              about: "A PROPOS",
              accessibility: "ACCESSIBILITE",
              arrivalTime: "HEURE D'ARRIVEE / DEPART",
            },
          },
          notification: {
            errorFile1: "Le fichier",
            errorFile2: "dépasse la taille maximale autorisée (8 Mo)",
            errorFile3:
              "Uniquement les fichiers du type png, jpeg, heif sont acceptés",
            only12Photos: "12 images au maximum peuvent etre upload",
            errorSendPhotos:
              "Il y a eu un problème avec les images, veuillez réessayer de les rajouter",
            residenceAdded: "Résidence ajoutée avec succès",
            nameAlreadyTaken: "Le nom de la résidence est déjà pris",
            errorAddResidence:
              "Une erreur est survenue lors de l'ajout de la résidence, veuillez réessayer",
            errorGiveLocation:
              "Veuillez reconfirmer la localisation de votre résidence",
            messageList: {
              1: "Nous nous occupons de tout",
              2: "Un peu de patience",
              3: "Le temps de tout vérifier",
              4: "C'est presque fini",
              5: "Nous traitons vos magnifiques photos",
              6: "Nous vérifions quelques données sensibles",
              7: "Pas de panique",
              8: "Nous sommes bientôt prêts",
              9: "Nous sommes en train de tout vérifier",
              10: "Optimisation des images en cours",
              11: "Vérification de la qualité des photos",
              12: "Analyse des métadonnées en cours",
              13: "Nous ajustons les dimensions",
              14: "Sauvegarde des données en cours",
              15: "Finalisation de l'upload",
              16: "Nous y sommes presque, merci pour votre patience",
              17: "Nous sommes en train de tout vérifier",
              18: "Traitement en cours, merci de patienter",
              19: "Nous travaillons pour améliorer votre expérience",
              20: "Rien n'est impossible, même le chargement",
              21: "Nous faisons le nécessaire pour vous",
              22: "Chargement en cours, restez avec nous",
              23: "Votre patience est grandement appréciée",
              24: "Nous rendons chaque pixel parfait",
              25: "Une étape à la fois, nous y arrivons",
              26: "La perfection prend du temps, merci pour votre compréhension",
              27: "Nous nous assurons que tout est conforme à vos attentes",
            },
            youNeedConnect: "Vous devez d'abord posseder un compte Trouvechap",
            youNeedCheckerDataEmail:
              "Vous devez verifier votre email avant de pouvoir ajouter une résidence",
            youNeedIdentityDoc:
              "Vous devez uploader votre pièce d'identité avant de pouvoir ajouter une résidence",
            youNeedPhoneNumber:
              "Vous devez ajouter un numéro de téléphone avant de pouvoir ajouter une résidence",
          },
        },
      },
    },
  },
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
