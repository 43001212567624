import React, { Fragment, useEffect, useRef, useState } from "react";
import ContainerPersoModal from "../ContainerPersoModal";

const cancelReasons = [
  {
    id: 1,
    text: "modals.booking.txts.motifCanceledValue1",
  },
  {
    id: 2,
    text: "modals.booking.txts.motifCanceledValue2",
  },
  {
    id: 3,
    text: "modals.booking.txts.motifCanceledValue3",
  },
  {
    id: 4,
    text: "modals.booking.txts.motifCanceledValue4",
  },
  {
    id: 5,
    text: "modals.booking.txts.motifCanceledValue5",
  },
  {
    id: 6,
    text: "modals.booking.txts.motifCanceledValue6",
  },
  {
    id: 0,
    text: "modals.booking.txts.motifCanceledValue0",
  },
];
function RefundRequestMenu({
  remboursementPanelOpen,
  setRemboursementPanelOpen,
  t,
  handleGoRemboursement,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [customCancelReason, setCustomCancelReason] = useState("");
  const [currentCancelReason, setCurrentCancelReason] = useState(null);
  const refTextComment = useRef(null);
  useEffect(() => {
    if (!remboursementPanelOpen) return;
    setCurrentCancelReason(null);
    setCustomCancelReason("");
    setCurrentStep(0);
  }, [remboursementPanelOpen]);

  const handleValidateCancelReason = () => {
    if (currentCancelReason?.id === 0 && currentStep === 1) {
      if (customCancelReason.length > 10) {
        handleGoRemboursement(customCancelReason);
      }
    } else {
      handleGoRemboursement(currentCancelReason.text);
    }
  };

  let canValidateCancelReason = currentCancelReason;
  if (currentCancelReason?.id === 0 && currentStep === 1) {
    canValidateCancelReason = customCancelReason.length > 10;
  }
  const isNext = currentStep === 0 && currentCancelReason?.id === 0;
  return (
    <ContainerPersoModal
      isOpen={remboursementPanelOpen}
      toggle={setRemboursementPanelOpen}
      title={t("modals.booking.titles.confirmRemboursementTxt")}
      forceTitle={true}
      baseTop={true}
      className="modal-details cancel-confirm refund-modal"
    >
      <div
        style={{
          position: "relative",
        }}
        className="confirm-panel second-conf flcolm cancel-reason-container-parent"
      >
        {currentStep === 0 && (
          <Fragment>
            <div className="cancel-reason-container flex flcolm">
              <p>{t("modals.booking.txts.titleCancelReason")}</p>
              <div className="selectors | flcolm">
                {cancelReasons.map((reason) => {
                  const isSelected = currentCancelReason?.id === reason.id;
                  return (
                    <button
                      style={{
                        borderRadius: 8,
                        backgroundColor: isSelected
                          ? "#dac7ff40"
                          : "transparent",
                      }}
                      key={reason.id}
                      onClick={() => {
                        setCurrentCancelReason(reason);
                      }}
                      className="select-btn | btn btw rlve"
                    >
                      <span>{t(reason.text)}</span>
                      <div className="selector-circle rlve">
                        {isSelected && (
                          <span className="center-absolute"></span>
                        )}
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          </Fragment>
        )}
        {currentStep === 1 && (
          <Fragment>
            <div style={{ marginBottom: 16 }} className="comment-div | flcolm">
              <textarea
                ref={refTextComment}
                maxLength={150}
                defaultValue={customCancelReason}
                onChange={(e) => setCustomCancelReason(e.target.value)}
                className="comment-part"
                placeholder={t(
                  "modals.booking.txts.motifCanceledValue0Placeholder"
                )}
              ></textarea>
              <span>{customCancelReason.length}/150</span>
            </div>
          </Fragment>
        )}
        <div className="btns | flcolm addgap8 refund-btn-cancel">
          <button
            disabled={!canValidateCancelReason}
            style={{
              borderRadius: 8,
            }}
            onClick={() => {
              if (isNext) {
                setCurrentStep(1);
              } else {
                handleValidateCancelReason();
              }
            }}
            className="cancel-btn | btn cc-btn "
          >
            {isNext
              ? t("modals.booking.txts.next")
              : t("modals.booking.button.confirmTxt")}
          </button>
        </div>
      </div>
    </ContainerPersoModal>
  );
}

export default RefundRequestMenu;
