import React, { useEffect, useRef, useState } from "react";
import closeBtn from "../../images/icons/x-close.svg";
import { useStorePageManager } from "../../store/PageManage";
import "../../styles/components/containerPersoModal.css";

export default function ContainerPersoModal({
  children,
  bottomChildren = true,
  className = "",
  isOpen,
  toggle,
  handlePersoOutModal,
  title = "",
  baseTop = true,
  fullBlock = false,
  noOverflow = false,
  forOverflow = false,
  fitContent = false,
  brum = false,
  noOpacity = false,
  overContent = false,
  forceTitle = false,
}) {
  const scrollableDivRef = useRef(null);
  const [putTopTitle, setPutTop] = useState(baseTop);
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  useEffect(() => {
    if (isOpen) {
      handleUpdateBodyOverflow(false);
    }
    if (forceTitle) return setPutTop(true);
    if (isOpen == false && baseTop == false) setPutTop(false);
    else if (isOpen == true && baseTop == true) setPutTop(true);
  }, [isOpen, handleUpdateBodyOverflow]);
  const handleScroll = () => {
    const scrollTop = scrollableDivRef.current.scrollTop;
    if (forceTitle) return setPutTop(true);
    if (scrollTop >= 50 && putTopTitle == false) setPutTop(true);
    else if (scrollTop < 50 && putTopTitle == true) setPutTop(false);
  };
  const handleOutModal = () => {
    if (handlePersoOutModal) handlePersoOutModal();
    if (!noOverflow) handleUpdateBodyOverflow(true);
    toggle(false);
  };
  useEffect(() => {
    return () => {
      useStorePageManager.getState().handleUpdateBodyOverflow(true);
    };
  }, []);
  if (!isOpen) return;

  return (
    <div
      className={`modal-content ${className} ${noOpacity ? "no-opacity" : ""} ${
        brum ? "brum-background" : ""
      } | flex jcenter liner`}
    >
      <div
        onClick={handleOutModal}
        className={`background-touch ${brum ? "brum-background" : ""}`}
      ></div>
      <div
        style={
          fitContent
            ? {
                width: "fit-content",
                maxHeight: overContent ? "95dvh" : "70dvh",
              }
            : { maxHeight: overContent ? "95dvh" : "70dvh" }
        }
        className="modal-contains | flcolm rlve"
      >
        <div className="top-header-modal | flrow liner">
          <button onClick={handleOutModal} className="btn-close | btn">
            <img src={closeBtn} alt="close-btn" />
          </button>
          {(putTopTitle || fullBlock) && (
            <h3 className="title-put-top">{title}</h3>
          )}
        </div>
        <div
          style={
            forOverflow
              ? {
                  overflow: "unset",
                }
              : {}
          }
          onScroll={handleScroll}
          ref={scrollableDivRef}
          className={`content ${bottomChildren ? "bottom-child" : ""} ${
            overContent ? "over-content" : ""
          } `}
        >
          {React.cloneElement(children, {
            handleOutModal,
          })}
        </div>
      </div>
    </div>
  );
}
